import React, { useEffect } from 'react';
import {
  makeStyles,
  Theme,
  useTheme,
  Grid,
  Typography,
  Divider,
  GridProps
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => {
  return {
    textStyle: {
      fontSize: theme.MetricsSizes.tiny_xxx,
      marginLeft: theme.spacing(1)
    }
  };
});

type Props = GridProps & {
  icon: any;
  value: any;
};

const ImageTextComponent = (props: Props) => {
  const { icon, value, ...rest } = props;
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Grid container alignItems="center" {...rest}>
      <img src={icon} />
      <Typography className={classes.textStyle}>{value}</Typography>
    </Grid>
  );
};

export default ImageTextComponent;
