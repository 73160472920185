import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Drawer,
  Grid,
  makeStyles,
  Theme,
  useTheme
} from '@material-ui/core';
import { LoginDrawerContext } from 'src/contexts/LoginDrawerContext';
import {
  Outlet,
  useLocation,
  useMatch,
  useNavigate,
  Pathname
} from 'react-router';
import CloseIcon from '@material-ui/icons/Close';
import { home4, home6, home7, icon1200, line1, LoginBtmImg } from 'src/Assets';
import { createBrowserHistory } from 'history';
import {
  btn2,
  stock,
  stock1,
  contactArrow,
  iconbox1,
  iconbox2,
  iconbox3,
  iconbox4,
  home1,
  home2,
  home3,
  client1,
  client2,
  client3,
  client4,
  client5,
  client6,
  aboutPh,
  google3,
  app3,
  logo1,
  mail,
  ph,
  f1,
  f2,
  f3
} from 'src/Assets';
import './website.css';
import { t } from 'i18next';
import { ButtonComp } from 'src/components';
import ArrowForward from '@material-ui/icons/ArrowForward';
const useStyles = makeStyles((theme: Theme) => ({
  outerContainer: {
    width: '100%'
  },
  leftContainer: {
    width: '43%'
  },
  rightContainer: {
    width: '57%'
  },
  //--------
  drawerWidth: {
    width: '520px'
  },
  loginDrawerStyle: {
    padding: theme.spacing(3.8),
    display: 'block'
  },
  imageStyle: {
    display: 'flex',
    paddingLeft: theme.spacing(7)
  }
}));

function LandingPage() {
  const classes = useStyles();
  const theme = useTheme();
  // const { isLoginDrawerOpen, closeLoginDrawer } =
  //   useContext(LoginDrawerContext);
  const navigateTo = useNavigate();
  const location = useLocation();
  // const match = useMatch('/');

  // const handleCloseIconClick = () => {
  //   closeLoginDrawer();
  //   navigateTo('/', { replace: true });
  // };

  const handleClickNavigation = (url: Pathname) => {
    navigateTo(url);
  };

  useEffect(() => {
    var li_elements: any = document.querySelectorAll('.wrapper_left ul li');
    var item_elements: any = document.querySelectorAll('.item');

    for (var i = 0; i < li_elements.length; i++) {
      li_elements[i].addEventListener('click', function () {
        li_elements.forEach(function (li) {
          li.classList.remove('active');
        });
        this.classList.add('active');
        var li_value: any = this.getAttribute('data-li');
        item_elements.forEach(function (item) {
          item.style.display = 'none';
        });
        let liQuery: any = document.querySelector('.' + li_value);
        if(li_value!==""){
           liQuery.style.display = 'block';
        }else{
          console.log('data-li is empty')
        }
    
      });
    }
  }, []);
  // for (var i = 0; i <div li_elements.length; i++) {
  //   li_elements[i].addEventListener('click', function () {
  //     console.log('Clicked a li option');
  //     li_elements.forEach(function (li) {
  //       li.classList.remove('active');
  //     });

  //     this.classList.add('active');
  //     var li_value: any = this.getAttribute('data-li');
  //     item_elements.forEach(function (item) {
  //       item.style.display = 'none';
  //     });
  //     let liQuery: any = document.querySelector('.' + li_value);
  //     if (li_value == 'cer-recycle') {
  //       liQuery.style.display = 'block';
  //     } else if (li_value == 'data-dest') {
  //       liQuery.style.display = 'block';
  //     } else if (li_value == 'it-refur') {
  //       liQuery.style.display = 'block';
  //     } else if (li_value == 'ewaste') {
  //       liQuery.style.display = 'block';
  //     } else if (li_value == 'doorto') {
  //       liQuery.style.display = 'block';
  //     } else if (li_value == 'reportto') {
  //       liQuery.style.display = 'block';
  //     } else {
  //       console.log('');
  //     }
  //   });
  // }


  return (
    <>
      <div>
        {/* landing1  */}
        <div className="landing1">
          <div className="home-first">
            <p className="home-subtitle">
              {t('WEBHOME.solutionfortheeverysolution')}
            </p>
            <h1 className="home-title">
              {t('WEBHOME.dustman')}
              <br />
              {t('WEBHOME.waste')}
              <br />
              {t('WEBHOME.management')}
              <br />& {t('WEBHOME.recycling')}
            </h1>
            <p className="home-sub">
              {t(
                'WEBHOME.leaderInElectricalWasteManagement&RecyclingSolutions'
              )}
            </p>
            <a onClick={() => handleClickNavigation('/about-us')}>
              <ButtonComp
                buttonText={t('Landing.knowMore')}
                btnWidth={167}
                height={55}
                style={{
                  marginBottom: '5rem'
                }}
                backgroundColor="#11294c"
                endIcon={
                  <ArrowForward style={{ margin: theme.spacing(1, 0) }} />
                }
              />
            </a>
          </div>
          <div className="home-pic">
            <img
              className="home-pic1"
              src={stock}
              alt=""
              width="500px"
              height="500px"
            />
            <div className="home-pic3">{t('Landing.services')}</div>
          </div>
        </div>
        {/*landing 2  */}
        <div className="landing2">
          <div className="round">
            <img src={iconbox1} style={{ height: 60, marginTop: 8 }} alt="" />
            <p style={{ marginTop: 6 }}>{t('WEBHOME.tnpscApproved')}</p>
          </div>
          {/* <div className="round">
            <div className = "certificate">
            <img src={certificate} style={{padding:15}} alt="" />
            </div>
            <p style={{marginTop:10}}>{t('WEBHOME.tnpscApproved')}</p>
          </div> */}

          <div className="round">
            <img src={iconbox2} alt="" />
            <p>{t('WEBHOME.StaffMembers')}</p>
          </div>

          <div className="round">
            <img src={iconbox3} alt="" />
            <p>{t('WEBHOME.bankSupport')}</p>
          </div>

          <div className="round">
            <img src={iconbox4} alt="" />
            <p>{t('WEBHOME.allVehicleTypes')}</p>
          </div>
        </div>
        {/* landing 3 */}
        <div className="landing3">
          <p className="home-subtitle1">{t('WEBHOME.areYOUThinking')}</p>
          <p className="home-title1">{t('WEBHOME.whyDustman')}</p>
          <div className="home-why">
            <div>
              <img src={home1} alt="" />
              <h3>{t('WEBHOME.specialisedRecycling')}</h3>
              <p>{t('WEBHOME.ourModernRecycling')}</p>
            </div>
            <div>
              <img src={home2} alt="" />
              <h3>{t('WEBHOME.collection')}</h3>
              <p>{t('WEBHOME.computer')}</p>
            </div>
            <div>
              <img src={home3} alt="" />
              <h3>{t('WEBHOME.integratedLogistics')}</h3>
              <p>{t('WEBHOME.theCost')}</p>
            </div>
          </div>
        </div>
        {/* landing 4 */}
        <div className="landing4">
          <div className="home-left">
            <img src={home4} alt="" />
            <p style={{ fontSize: '18px', fontWeight: 'bold', color: 'black' }}>
              {t('WEBHOME.monthlyMaterial')}
            </p>
            <div style={{ display: 'flex' }}>
              <p
                style={{ fontSize: '18px', fontWeight: 'bold', color: 'black' }}
              >
                {t('WEBHOME.capacity')}
              </p>
              <img style={{ marginLeft: '30px' }} src={icon1200} alt="" />
            </div>

            <img src={line1} alt="" />
            {/* <img className="cap1" src={home6} alt="" /> */}
            {/* <img className="cap2" src={home7} alt="" /> */}
          </div>

          <div className="home-right">
            <div className="right-text">
              <p>{t('WEBHOME.weAre')}</p>
              <h2 className="home-title1">{t('WEBHOME.whatWeDo')}</h2>
            </div>
            <div className="wrapper_left">
              <ul>
                <li data-li="cer-recycle" className="active">
                  <p>{t('WEBHOME.certifiedRecycling')}</p>
                </li>
                <li data-li="data-dest">
                  <p>{t('WEBHOME.dataDestruction')}</p>
                </li>
                <li data-li="it-refur">
                  <p>{t('WEBHOME.itRefurbish')}</p>
                </li>
                <li data-li="ewaste">
                  <p>{t('WEBHOME.eWasteScrap')}</p>
                </li>
                <li data-li="doorto">
                  <p>{t('WEBHOME.doorToDoorCollection')}</p>
                </li>
                <li data-li="reportto">
                  <p>{t('WEBHOME.reportToCustomers')}</p>
                </li>
              </ul>
            </div>

            <div className="wrapper_right">
              <div className="item cer-recycle">
                <h3>{t('WEBHOME.certifiedRecycling')}</h3>
                <p className="psg">{t('WEBHOME.weProvide')}</p>
              </div>
              <div className="item data-dest" style={{ display: 'none' }}>
                <h3>{t('WEBHOME.dataDestruction')}</h3>
                <p className="psg">{t('WEBHOME.dataDestructionPara')}</p>
              </div>
              <div className="item it-refur" style={{ display: 'none' }}>
                <h3>{t('WEBHOME.itRefurbish')}</h3>
                <p className="psg">{t('WEBHOME.ITRefurbishPara')}</p>
              </div>
              <div className="item ewaste" style={{ display: 'none' }}>
                <h3>{t('WEBHOME.eWasteScrap')}</h3>
                <p className="psg">{t('WEBHOME.eWasteScrapPara')}</p>
              </div>
              <div className="item doorto" style={{ display: 'none' }}>
                <h3>{t('WEBHOME.doorToDoorCollection')}</h3>
                <p className="psg">{t('WEBHOME.doortodoorcollectionPara')}</p>
              </div>
              <div className="item reportto" style={{ display: 'none' }}>
                <h3>{t('WEBHOME.reportToCustomers')}</h3>
                <p className="psg">{t('WEBHOME.reporttocustomerPara')}</p>
              </div>
            </div>
          </div>
        </div>
        {/* landing 5*/}
        <div className="landing5">
          <div className="client-title">
            <p>{t('WEBHOME.ourCLIENTS')}</p>
            <h3>
              {t('WEBHOME.clientsWe')}
              <br />
              {t('WEBHOME.WorkFor')}
            </h3>
          </div>

          <div className="client-list">
            <div className="client">
              <img src={client1} alt="" />
            </div>
            <div className="client">
              <img src={client2} alt="" />
            </div>
            <div className="client">
              <img src={client3} alt="" />
            </div>
            <div className="client">
              <img src={client4} alt="" />
            </div>
            <div className="client">
              <img src={client5} alt="" style={{ mixBlendMode: 'darken' }} />
            </div>
            <div className="client">
              <img src={client6} alt="" />
            </div>
          </div>
        </div>
        {/* landing 6 */}
        <div className="landing6">
          <img className="home-ph-image" src={aboutPh} alt="" />
          <div className="ph-text">
            <h5>{t('WEBHOME.mobileApps')}</h5>
            <p
              style={{
                fontSize: '35px',
                fontWeight: 700,
                marginBottom: '20px'
              }}
            >
              {t('WEBHOME.bookYour')}
              <br />
              {t('WEBHOME.pickupFrom')}
              <br />
              {t('WEBHOME.ourApp')}
            </p>
            <p>{t('WEBHOME.dedicatedMobile')}</p>
            <div className="play-btn">
              {/* <img src="images/111.png" alt="">  */}
              <img className="play-btn-img" src={google3} alt="" />
              <img className="play-btn-img" src={app3} alt="" />
            </div>
          </div>
        </div>
        {/* landing 7 */}
        <div className="landing7">
          <p className="pre-head">{t('WEBHOME.contacts')}</p>
          <p className="head">{t('WEBHOME.questions')}</p>
          <p></p>
          <p className="get-in">{t('WEBHOME.getInTouch')}</p>
          <p>
            <a onClick={() => handleClickNavigation('/contact-us')}>
              <button className="contact-btn" style={{ width: '200px' }}>
                {t('WEBHOME.contactUs')} <img src={contactArrow} alt="" />
              </button>
            </a>
          </p>
        </div>
      </div>
      {/* {
        <Drawer
          open={isLoginDrawerOpen}
          anchor={'right'}
          classes={{ paper: classes.drawerWidth }}
        >
          <Grid className={classes.loginDrawerStyle}>
            <CloseIcon onClick={handleCloseIconClick} />
            <Box
              sx={{
                padding: theme.spacing(4, 10, 6, 3.8)
              }}
            >
              <Outlet />
            </Box>
            <Grid className={classes.imageStyle}>
              <img src={LoginBtmImg} alt="LoginBtmImg" />
            </Grid>
          </Grid>
        </Drawer>
      } */}
    </>
  );
}

export default LandingPage;
