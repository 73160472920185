import React from 'react';
import {
  Grid,
  makeStyles,
  Theme,
  Typography,
  useTheme
} from '@material-ui/core';
import { PlaceMaker } from 'src/Assets';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useUserInfo from 'src/hooks/useUserInfo';
import { useTranslation } from 'react-i18next';
import { BorderColor } from '@material-ui/icons';
import { useNavigate } from 'react-router';
const useStyles = makeStyles((theme: Theme) => ({
  addressStyle: {
    padding: theme.spacing(0, 1),
    color: theme.Colors.lightBlueGrey,
    paddingTop: 8
  },
  iconStyle: {
    padding: theme.spacing(1, 1)
  },
  linkStyle: {
    //fontSize: theme.MetricsSizes.small_xxx
    //padding: theme.spacing(1, 1),
    color: theme.Colors.blueLight,
    paddingTop: 8,
    //borderBottom : "1px solid",
    borderColor: theme.Colors.blueLight,
    cursor: 'pointer'
  },
  container: {
    alignItems: 'center'
  }
}));

function AddressDisplay() {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();  
  const navigateTo = useNavigate();
  const { userDetails, updateTabClicked } = useUserInfo();

  return (
    <Grid container direction="row" className={classes.container}>
      <PlaceMaker />
      <Typography variant="subtitle1" className={classes.addressStyle}>
        {userDetails?.full_address}
      </Typography>
      <Typography
        onClick={() => {
          console.log('Clicked');
          updateTabClicked(3);
          navigateTo('/customer-home', {
            replace: true
          });
        }}
        variant="subtitle2"
        className={classes.linkStyle}
      >
        {t('PICKUP.change')}
      </Typography>
    </Grid>
  );
}

export default AddressDisplay;
