import { apiOptions } from 'src/Utils/apiOptions';
import { apiRequest } from 'src/Utils/apiRequest';
import { Config } from 'src/Config';
import {
  vendorCustomerOrder,
  vendorCustomerOrderCompleted,
  vendorCustomerOrderScheduled
} from './Stub/VendorAppStub';
import { DefaultProp } from './customerOrderService';

interface CreateBuyingOrderProp extends Omit<DefaultProp, 'data'> {
  data: {
    buyedOrderList: number[];
  };
}

interface PaymentVerifyProp extends Omit<DefaultProp, 'data'> {
  data: {
    buyedOrderList: number[];
    paymentDetails?: {
      razorpay_order_id: number;
      razorpay_payment_id: number;
      razorpay_signature: string;
    };
  };
  vendorId: number;
}

export const vendorCustomerOrderService = {
  getCustomerOrderByVendorLocation: async (vendorId: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getOrderByVendorLocation/vendor/${vendorId}`,
      method: 'get'
    });
    //return vendorCustomerOrder.VendorCustomerOrderGet;
    return apiRequest(options);
  },

  getAllVendorScheduledOrder: async (vendorId: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getAllVendorScheduledOrders/vendor/${vendorId}`,
      method: 'get'
    });
    //return vendorCustomerOrderScheduled.VendorCustomerOrderGet;
    return apiRequest(options);
  },

  getAllVendorCompletedOrder: async (vendorId: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getAllVendorCompletedOrders/vendor/${vendorId}`,
      method: 'get'
    });
    //return vendorCustomerOrderCompleted.VendorCustomerOrderGet;
    return apiRequest(options);
  },

  createVendorBuyingOrder: async ({
    data,
    successMessage,
    failureMessage
  }: CreateBuyingOrderProp) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/createPaymentOrder`,
      method: 'post',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },

  paymentVerifySignature: async ({
    vendorId,
    data,
    successMessage,
    failureMessage
  }: PaymentVerifyProp) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/buyingCustomerOrder/vendor/${vendorId}`,
      method: 'post',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  }
};
