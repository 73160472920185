import {
  Checkbox,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useTheme
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { locationIcon, weightIcon } from 'src/Assets';
import { ButtonComp, ImageTextComponent } from 'src/components';
type StyleProp = {
  checkBoxColor?: string;
};

const useStyles = makeStyles<Theme, StyleProp>((theme: Theme) =>
  createStyles({
    outerContainer: {
      border: '0.5px solid',
      borderColor: theme.Colors.greyDark,
      width: '100%',
      marginBottom: theme.spacing(3),
      padding: theme.spacing(3.1, 2, 3.8, 3),
      background: theme.Colors.whiteLightGrey
    },
    contentContainer: {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(2)
    },

    subText: {
      fontSize: theme.MetricsSizes.tiny_xxx,
      color: theme.Colors.darkGrey
    },
    status: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2)
    },
    category: {
      fontSize: theme.MetricsSizes.small_x
    },
    categoryText: {
      color: theme.Colors.mediumGrey,
      marginLeft: theme.MetricsSizes.tiny
    },
    imageAlign: {
      paddingLeft: theme.spacing(4.5)
    },
    buttonAlign: {
      display: 'flex',
      paddingTop: theme.spacing(3),
      flexDirection: 'row',
      alignItems: 'center'
    },
    imageStyle: {
      width: 100,
      height: 100
    },
    checkbox: {
      '&.MuiCheckbox-colorSecondary.Mui-checked': {
        backgroundColor: theme.Colors.white,
        color: (prop) => prop.checkBoxColor || theme.Colors.orangePrimary
      }
    }
  })
);

type OrderCompProp = {
  orderItems: any;
  isButtonOne?: boolean;
  isButtonTwo?: boolean;
  handleClickButtonOne?: (orderData: any) => void;
  handleClickButtonTwo?: (orderData: any) => void;
  isCheckBox?: boolean;
  handleCheckboxChange?: (orderData: any[], isChecked: boolean) => void;
  buttonOneText?: string;
  buttonTwoText?: string;
  orderStatusText?: string;
  statusIcon?: any;
  buttonOneStyle?: React.CSSProperties;
  buttonTwoStyle?: React.CSSProperties;
  isButtonThree?: boolean;
  buttonThreeText?: string;
  buttonFourText?: string;
  buttonThreeStyle?: React.CSSProperties;
  checkBoxColor?: string;
  handleClickButtonThree?: (orderData: any) => void;
};

const UHOrderPreviewComp = (props: OrderCompProp) => {
  const {
    orderItems,
    isButtonTwo = false,
    isButtonOne = true,
    handleClickButtonOne,
    handleClickButtonTwo,
    isCheckBox = false,
    handleCheckboxChange,
    buttonTwoText,
    buttonOneText,
    orderStatusText,
    statusIcon,
    buttonOneStyle,
    buttonTwoStyle,
    isButtonThree,
    buttonThreeText,
    buttonFourText,
    buttonThreeStyle,
    checkBoxColor,
    handleClickButtonThree
  } = props;
  const classes = useStyles({ checkBoxColor });
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <Grid
        container
        className={classes.outerContainer}
        alignItems="flex-start"
      >
        <Grid container item xs>
          {orderItems?.order_images?.length ? (
            <Grid item>
              <img
                src={orderItems?.order_images[0]}
                alt="images"
                className={classes.imageStyle}
              />
            </Grid>
          ) : null}
          {/* {console.log('orderItems', orderItems)} */}
          <Grid item xs container className={classes.contentContainer}>
            <Grid item xs={12}>
              <Typography className={classes.subText}>
                {t('orders')}
                {orderItems?.id}
              </Typography>
            </Grid>
            <Grid item xs={12} container alignItems="flex-start">
              <Grid item xs={5} container alignItems="baseline">
                <Grid item>
                  <Typography variant="subtitle2">{t('category')}:</Typography>
                </Grid>
                <Grid item xs>
                  <Typography variant="body2" className={classes.categoryText}>
                    {orderItems?.order_items?.join(', ')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item className={classes.imageAlign}>
                <ImageTextComponent
                  icon={weightIcon}
                  value={`${orderItems?.quantity_kg} Kg`}
                />
              </Grid>
              <Grid item className={classes.imageAlign}>
                <ImageTextComponent
                  icon={locationIcon}
                  value={orderItems?.city}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.buttonAlign}>
              {isButtonOne ? (
                <ButtonComp
                  buttonText={buttonOneText || `${t('ORDER.viewDetails')}`}
                  backgroundColor={theme.Colors.whiteLightGrey}
                  buttonFontSize={theme.MetricsSizes.tiny_xxx}
                  variant="outlined"
                  buttonTextColor={theme.Colors.secondary}
                  height={theme.MetricsSizes.medium_xx}
                  btnWidth={'110px'}
                  style={{
                    marginRight: theme.spacing(1.25),
                    borderColor: theme.Colors.secondary,
                    borderRadius: theme.MetricsSizes.tiny,
                    ...buttonOneStyle
                  }}
                  onClickButton={() => handleClickButtonOne(orderItems)}
                />
              ) : null}
              {isButtonTwo ? (
                <ButtonComp
                  buttonText={buttonTwoText || `${t('ORDER.cancelButton')}`}
                  buttonFontSize={theme.MetricsSizes.tiny_xxx}
                  buttonTextColor={theme.Colors.white}
                  height={theme.MetricsSizes.medium_xx}
                  style={{
                    marginRight: theme.spacing(1.25),
                    borderRadius: theme.MetricsSizes.tiny,
                    ...buttonTwoStyle
                  }}
                  btnWidth={'72px'}
                  onClickButton={() => handleClickButtonTwo(orderItems)}
                />
              ) : null}
              {isButtonThree ? (
                <ButtonComp
                  buttonText={buttonThreeText || 'COMPLETE'}
                  buttonFontSize={theme.MetricsSizes.tiny_xxx}
                  buttonTextColor={theme.Colors.white}
                  height={theme.MetricsSizes.medium_xx}
                  style={{
                    borderRadius: theme.MetricsSizes.tiny,
                    ...buttonThreeStyle
                  }}
                  btnWidth={'72px'}
                  onClickButton={() => handleClickButtonThree(orderItems)}
                />
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        {isCheckBox && (
          <Grid item>
            <Checkbox
              className={classes.checkbox}
              onChange={(_, checked) =>
                handleCheckboxChange(orderItems, checked)
              }
            />
          </Grid>
        )}
        {orderStatusText && (
          <Grid item>
            <Grid className={classes.status}>
              <Typography className={classes.subText}>
                {orderStatusText}
              </Typography>
              <img src={statusIcon} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};
export default UHOrderPreviewComp;
