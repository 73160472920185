import { apiOptions } from 'src/Utils/apiOptions';
import { apiRequest } from 'src/Utils/apiRequest';
import { Config } from 'src/Config';

export const pushNotificationService = {
  getAllPushNotification: async (user_type_id: number, user_id: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getAllNotifications/user_type/${user_type_id}/user_id/${user_id}`,
      method: 'get'
    });
    return apiRequest(options);
  },
  clearAllNotification: async (user_type_id: number, user_id: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/deleteAllNotifications/user_type/${user_type_id}/user_id/${user_id}`,
      method: 'delete'
    });
    return apiRequest(options);
  }
};
