import {
  Divider,
  Grid,
  useTheme,
  Theme,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Image, Phone, Scales, MapPin, DateIcon, MapTrifold, Clock } from 'src/Assets';
import {
  ButtonComp,
  DialogContentDetails,
  Heading,
  LinkComp,
  UHIconTextComp
} from 'src/components';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { useTranslation } from 'react-i18next';
import { getDateFormat } from 'src/Utils';
import CarouselContent from '../Carousel';
import { timeSlotDetails } from '../SlotButtonComp';
// import {
  
//   Published
// } from 'src/Assets/Images';

const useStyles = makeStyles((theme: Theme) => {
  return {
    dividerStyle: {
      padding: theme.spacing(2, 0)
    },
    imgStyle: {
      borderRadius: 10
    },
    btnStyle: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      paddingTop: theme.spacing(4)
    },
    orderDetail: {
      color: theme.Colors.mediumBlack,
      marginBottom: theme.MetricsSizes.tiny,
      fontWeight: theme.fontWeight.regular
    },
    listStyle: {
      marginTop: theme.spacing(1.75)
    },
    imgContainer: {
      paddingLeft: theme.spacing(1.6),
      display: 'flex',
      width: 'auto',
      overflowX: 'auto'
    },
    listStyle1: {
      marginTop: 10,
      fontWeight: theme.fontWeight.regular,
      color: theme.Colors.blackPrimary,
      fontSize: theme.MetricsSizes.regular
    },
    listStyle2: {
      marginTop: 10,
      marginLeft: 15,
      fontWeight: theme.fontWeight.regular,
      color: theme.Colors.blackPrimary,
      fontSize: theme.MetricsSizes.regular
    }
  };
});

type Props = {
  orderData: any;
  onCancelButtonClick?: () => void;
  cancelButtonColor?: string;
  headingColor?: string;
  isBlur?: boolean;
  isCompletedOrder?: boolean;
  isConfirmedOrder?: boolean;
  isPendingOrder?: boolean;
};

const UHOrderModalComponent = (props: Props) => {
  const {
    orderData,
    onCancelButtonClick,
    cancelButtonColor,
    headingColor,
    isBlur = false,
    isPendingOrder,
    isConfirmedOrder,
    isCompletedOrder
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();

  const contentDetails = [
    { content: t('category'), value: orderData?.order_items?.join(', ') },
    { content: t('location'), value: orderData?.city }
  ];

  {
    console.log('orderData..............', orderData);
  }
  const orderVendorContent = isConfirmedOrder
    ? [
        {
          content: t('ORDER.vendorName'),
          value: orderData?.vendor_name || orderData?.contact_name
        },
        {
          content: t('ORDER.mobileNumber'),
          value:
            orderData?.vendor_mobile_number?.slice(2) ||
            orderData?.vendor_profile_mobile_number
        }
      ]
    : [];

  return (
    <Grid container>
      <Grid item xs={12}>
        <DialogContentDetails
          contentDetails={contentDetails}
          leftContentFontSize={theme.MetricsSizes.regular}
          rightContentFontSize={theme.MetricsSizes.regular}
        />
      </Grid>
      <Grid item xs={8} className={classes.listStyle}>
        <UHIconTextComp
          isBlur={isBlur}
          icon={MapPin}
          value={`${orderData.address_line1}, ${orderData.address_line2},  ${orderData.address_line3},  ${orderData.city}, ${orderData.state}, ${orderData.pincode}`}
        />
      </Grid>
      {isCompletedOrder && (
        <>
          <Grid item xs={12} className={classes.dividerStyle}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Heading
              headingText={t('orderCompleted')}
              headerFontSize={theme.MetricsSizes.regular_xxx}
              headerFontWeight={theme.fontWeight.bold}
              headingColor={headingColor || theme.Colors.secondary}
            />
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction="row"
            alignItems="center"
            className={classes.orderDetail}
            spacing={1}
          >
            <Grid item>
              <Typography variant="h4">
                {`Picked up - ${
                  getDateFormat(orderData?.pickup_time).getDay
                }, ${getDateFormat(orderData?.pickup_time).getDate} ${
                  getDateFormat(orderData?.pickup_time).getMonth
                }`}
              </Typography>
            </Grid>
            <Grid item component="div">
              <DateIcon width={18} height={18} />
            </Grid>
            <Grid item>
              <Typography variant="h4">
                {
                  timeSlotDetails.find((item) => item.value === orderData?.slot)
                    .time
                }
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
      <Grid
        item
        xs={12}
        container
        alignItems="center"
        className={classes.listStyle}
      >
        <Grid item xs={4}>
          <UHIconTextComp
            icon={Phone}
            isBlur={isBlur}
            value={orderData?.registered_mobile_number?.substring(2)}
          />
        </Grid>
        <Grid item xs>
          <UHIconTextComp
            isCrown={isBlur}
            icon={MapTrifold}
            textContentStyle={{
              paddingTop: isBlur ? theme.MetricsSizes.small_xx : 0
            }}
            renderComponent={() => {
              return (
                <LinkComp
                  title={'Map Location'}
                  url={orderData?.map_url || orderData?.dustman_map_location}
                  color={theme.Colors.mediumGrey}
                  //underline="none"
                  style={{ fontSize: theme.MetricsSizes.regular }}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.listStyle}>
        <>
          <UHIconTextComp
            icon={Scales}
            value={`${orderData?.quantity_kg} kgs`}
          />
          {isPendingOrder && (
            <Grid
              item
              xs={12}
              container
              direction="row"
              alignItems="center"
              className={classes.orderDetail}
              spacing={1}
            >
              <Grid
                item
                style={{ display: 'grid', marginTop: '10px' }}
                // className={classes.listStyle1}
              >
                <img src={Clock} width={20} height={20} />
              </Grid>

              <Grid
                item
                // // style={{ marginLeft: '15px', marginTop: '10px' }}
                // className={classes.listStyle2}
              >
                <Typography variant="h4" className={classes.listStyle2}>
                  {`Scheduled - ${
                    getDateFormat(orderData?.pickup_time).getDay
                  }, ${getDateFormat(orderData?.pickup_time).getDate} ${
                    getDateFormat(orderData?.pickup_time).getMonth
                  }`}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="h4"
                  // style={{ marginTop: '10px' }}
                  className={classes.listStyle1}
                >
                  {
                    timeSlotDetails.find(
                      (item) => item.value === orderData?.slot
                    ).time
                  }
                </Typography>
              </Grid>
            </Grid>
          )}
        </>
      </Grid>

      <Grid item xs={12} className={classes.listStyle}>
        <UHIconTextComp
          icon={Image}
          textContentStyle={{ width: '90%' }}
          renderComponent={() => {
            return orderData?.order_images?.length ? (
              <CarouselContent
                data={orderData.order_images}
                show={2.5}
                isCustomizedCarousel
                carouselImageStyle={{ height: 150, width: 215 }}
                imageWidth={100}
              />
            ) : null;
          }}
        />
      </Grid>
      {isPendingOrder && (
        <>
          <Grid item xs={12} className={classes.btnStyle}>
            <ButtonComp
              buttonText={t('ORDER.cancelButton')}
              backgroundColor={cancelButtonColor || theme.Colors.secondary}
              buttonFontSize={theme.MetricsSizes.regular}
              buttonTextColor={theme.Colors.white}
              buttonFontWeight={theme.fontWeight.medium}
              btnBorderRadius={210}
              height={theme.MetricsSizes.large_xxx}
              btnWidth={142}
              onClickButton={onCancelButtonClick}
            />
          </Grid>
        </>
      )}

      {isConfirmedOrder && (
        <>
          <Grid item xs={12} className={classes.dividerStyle}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Heading
              headingText={t('vendorDetails')}
              headerFontSize={theme.MetricsSizes.regular_xxx}
              headerFontWeight={theme.fontWeight.bold}
              headingColor={headingColor || theme.Colors.secondary}
            />
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction="row"
            alignItems="center"
            className={classes.orderDetail}
            spacing={1}
          >
            <Grid item>
              <Typography variant="h4">
                {`Scheduled - ${
                  getDateFormat(orderData?.pickup_time).getDay
                }, ${getDateFormat(orderData?.pickup_time).getDate} ${
                  getDateFormat(orderData?.pickup_time).getMonth
                }`}
              </Typography>
            </Grid>
            <Grid item style={{ display: 'grid' }}>
              <DateIcon width={18} height={18} />
            </Grid>
            <Grid item>
              <Typography variant="h4">
                {
                  timeSlotDetails.find((item) => item.value === orderData?.slot)
                    .time
                }
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <DialogContentDetails
              contentDetails={orderVendorContent}
              leftContentFontSize={theme.MetricsSizes.small_xx}
              rightContentFontSize={theme.MetricsSizes.small_xxx}
              rightContentFontWeight={theme.fontWeight.medium}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default UHOrderModalComponent;
