import React, { useCallback, useEffect, useState } from 'react';
import { Theme, useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import VendorOrderModal from './vendorOrderModal';
import { API_SERVICES } from 'src/dustmanUI/Services';
import { useTranslation } from 'react-i18next';
import {
  CONFIRM_MODAL,
  CUSTOMER_ORDER_STATUS,
  HTTP_STATUSES,
  ORIENTATION,
  VENDOR_ORDER_STATUS
} from 'src/Config/constant';
import {
  Loader,
  UHConfirmModal,
  UHOrderPreviewComp,
  UHTabComponent
} from 'src/components';
import {
  yetToConfirmVendor,
  confirmVendor,
  pending,
  Completed,
  confirmed,
  confirmedWhite,
  pendingWhite,
  completedWhite
} from 'src/Assets';
import useVendorInfo from 'src/hooks/useVendorInfo';
import { getDateFormat } from 'src/Utils';
import toast from 'react-hot-toast';
import RescheduleOrderModal from 'src/components/RescheduleOrderModal.tsx';
import { useEdit } from 'src/hooks/useEdit';

const useStyles = makeStyles((theme: Theme) => ({
  contentContainer: {
    padding: theme.spacing(6.5, 3, 6.5, 3),
    background: theme.Colors.whitePure
  },
  outerContainer: {
    margin: theme.spacing(1.75, 0, 1.75, 0),
    background: theme.Colors.whitePure
  },
  tabContainer: {
    border: '0.5px solid',
    borderColor: theme.Colors.greyDark,
    paddingTop: theme.spacing(4)
  },
  tabContentStyle: {
    height: 513,
    overflowY: 'scroll'
  },
  tabContentContainer: {
    margin: theme.spacing(0, 2)
  },
  selectedTabStyle: {
    color: theme.Colors.whitePure,
    background: theme.Colors.orangePrimary,
    fontWeight: theme.fontWeight.medium
  }
}));

function OrdersPage() {
  const classes = useStyles();
  const theme = useTheme();
  const { vendorDetails } = useVendorInfo();
  const [selectedTab, setSelectedTab] = useState<number>(
    VENDOR_ORDER_STATUS.Pending
  );
  const initialValues = {
    vendor_order_drop_details: {
      pickup_time: '',
      slot: ''
    },
    vendor_order_pickup_details: {
      pickup_time: '',
      slot: ''
    }
  };
  const edit = useEdit(initialValues);
  const [orderDetails, setOrderDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState<any>({ open: false });
  const [confirmModal, setConfirmModal] = useState<any>({
    open: false
  });
  const [scheduleOpen, setScheduleOpen] = useState<any>({ open: false });
  const { t } = useTranslation();

  const onClickViewDetails = (orderData: any) => {
    setModalOpen({
      open: true,
      orderData: orderData
    });
  };

  const fetchData = useCallback(async () => {
    try {
      setOrderDetails([]);
      const response: any =
        await API_SERVICES.vendorMyOrderService.getVendorOrderByStatus(
          vendorDetails?.vendor_id,
          selectedTab
        );
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (response?.data?.orders) {
          setOrderDetails(response.data.orders);
        }
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  }, [selectedTab]);

  const OrdersTabItems = [
    {
      tabIcon: () =>
        selectedTab == CUSTOMER_ORDER_STATUS.Pending ? (
          <img src={pendingWhite} />
        ) : (
          <img src={pending} />
        ),
      label: t('ORDER.pending'),
      value: CUSTOMER_ORDER_STATUS.Pending
    },
    {
      tabIcon: () =>
        selectedTab == CUSTOMER_ORDER_STATUS.Confirmed ? (
          <img src={confirmedWhite} />
        ) : (
          <img src={confirmed} />
        ),
      label: t('ORDER.scheduled'),
      value: CUSTOMER_ORDER_STATUS.Confirmed
    },
    {
      tabIcon: () =>
        selectedTab == CUSTOMER_ORDER_STATUS.Completed ? (
          <img src={completedWhite} />
        ) : (
          <img src={Completed} />
        ),
      label: t('ORDER.completed'),
      value: CUSTOMER_ORDER_STATUS.Completed
    }
  ];

  const onClickRescheduleButton = (orderData) => {
    setScheduleOpen({
      open: true,
      orderData: orderData
    });
  };

  const updateSelectedDate = (dateString: string, slot: string) => {
    edit.update({
      vendor_order_drop_details: {
        ...edit.edits.vendor_order_drop_details,
        pickup_time: dateString,
        slot: slot
      },
      vendor_order_pickup_details: {
        ...edit.edits.vendor_order_pickup_details,
        pickup_time: dateString,
        slot: slot
      }
    });
  };

  const onRescheduleConfirmClick = async (orderData) => {
    try {
      const response: any = await Promise.all([
        API_SERVICES.vendorMyOrderService.replaceVendorOrder(orderData?.id, {
          data: { ...edit.edits.vendor_order_drop_details },
          successMessage: `${t('TOAST.orderUpdatedSuccessfully')}`
        }),
        API_SERVICES.vendorMyOrderService.replaceVendorPickupOrder(
          orderData?.id,
          {
            data: { ...edit.edits.vendor_order_pickup_details }
          }
        )
      ]);
      if (response[0]?.status < HTTP_STATUSES.BAD_REQUEST) {
        setScheduleOpen({ open: false });
        fetchData();
      }
      if (response[1]?.status < HTTP_STATUSES.BAD_REQUEST) {
        setScheduleOpen({ open: false });
        fetchData();
      }
    } catch (err) {
      toast.error(err?.message);
    }
  };

  const onClickCancelButton = (orderData: any) => {
    const onCancelClick = () => {
      setConfirmModal({ open: false });
    };
    const onConfirmClick = async () => {
      let updateData = {
        status_id: 4,
        orders: [orderData?.order_id]
      };
      const response: any = await API_SERVICES.generalService.replaceOrder({
        data: updateData,
        successMessage: `${t('TOAST.orderCancelledSuccessfully')}`
      });
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        onCancelClick();
        setModalOpen({ open: false });
        fetchData();
      }
    };
    let props = {
      color: theme.Colors.redPrimary,
      description: t('ORDER.cancelCustomerOrder'),
      title: t('cancelOrder'),
      iconType: CONFIRM_MODAL.cancel
    };
    setConfirmModal({ open: true, onConfirmClick, onCancelClick, ...props });
  };

  const renderTabContent = () => {
    return (
      <Grid className={classes.tabContentContainer}>
        {orderDetails?.length ? (
          orderDetails.map((item, index) => {
            const { getTime, getDateString } = getDateFormat(item?.pickup_time);

            return (
              <Grid key={index}>
                <UHOrderPreviewComp
                  orderItems={item}
                  isButtonOne
                  handleClickButtonOne={onClickViewDetails}
                  handleClickButtonTwo={onClickCancelButton}
                  handleClickButtonThree={onClickRescheduleButton}
                  buttonThreeText={t('ORDER.reschedule')}
                  orderStatusText={
                    (item?.status_id === CUSTOMER_ORDER_STATUS.Pending &&
                      'Yet to Confirm') ||
                    (item?.status_id === CUSTOMER_ORDER_STATUS.Confirmed &&
                      `Scheduled on ${getDateString}, ${getTime}`) ||
                    (item?.status_id === CUSTOMER_ORDER_STATUS.Completed &&
                      `Completed on ${getDateString}, ${getTime}`)
                  }
                  statusIcon={
                    item?.status_id === CUSTOMER_ORDER_STATUS.Pending
                      ? yetToConfirmVendor
                      : confirmVendor
                  }
                  isButtonTwo={
                    item?.status_id === CUSTOMER_ORDER_STATUS.Pending
                  }
                  buttonTwoStyle={{
                    background: theme.Colors.orangePrimary
                  }}
                  buttonThreeStyle={{
                    background: theme.Colors.orangePrimary
                  }}
                  isButtonThree={
                    item?.status_id === CUSTOMER_ORDER_STATUS.Confirmed
                  }
                  buttonOneStyle={{
                    border: '0.5px solid',
                    borderColor: theme.Colors.greyDark,
                    color: theme.Colors.orangePrimary
                  }}
                />
              </Grid>
            );
          })
        ) : (
          <Typography variant="body2">NO ORDERS FOUND!</Typography>
        )}
      </Grid>
    );
  };

  const onTabChange = (tabValue: any) => {
    setSelectedTab(tabValue);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading) {
    return <Loader />;
  } else {
    return (
      <>
        <Grid className={classes.outerContainer}>
          <Grid className={classes.contentContainer}>
            <UHTabComponent
              currentTabVal={selectedTab}
              tabContent={OrdersTabItems}
              orientation={ORIENTATION.VERTICAL}
              tabClasses={{ selected: classes.selectedTabStyle }}
              tabIndicatorColor={theme.Colors.primary}
              isDivider={false}
              tabContainerClassName={classes.tabContainer}
              renderTabContent={renderTabContent}
              tabContentClassName={classes.tabContentStyle}
              onTabChange={onTabChange}
            />
          </Grid>
        </Grid>
        {modalOpen.open && (
          <VendorOrderModal
            onClose={() => setModalOpen({ open: false })}
            {...modalOpen}
            onCancelButtonClick={onClickCancelButton}
          />
        )}
        {scheduleOpen.open && (
          <RescheduleOrderModal
            onClose={() => setScheduleOpen({ open: false })}
            onCancelClick={() => setScheduleOpen({ open: false })}
            {...scheduleOpen}
            onRescheduleConfirmClick={onRescheduleConfirmClick}
            updateSelectedDate={updateSelectedDate}
            color={theme.Colors.orangePrimary}
            activeButtonColor={theme.Colors.orangePrimary}
            activeTileColor={theme.Colors.orangePrimary}
          />
        )}
        {confirmModal.open && <UHConfirmModal {...confirmModal} />}
      </>
    );
  }
}

export default OrdersPage;
