import { Grid, Typography, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { ButtonComp, LoginHeaderComp, UHSelectComp } from 'src/components';
import { useEdit } from 'src/hooks/useEdit';
import { TermsAndConditionComp } from 'src/dustmanUI/content/Login/TermsAndConditionComp';
import { API_SERVICES } from 'src/dustmanUI/Services';
import { HTTP_STATUSES } from 'src/Config/constant';

const ChooseDeposit = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigateTo = useNavigate();
  const { state }: any = useLocation();
  const edit = useEdit(state?.formData);
  const depositData = [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 2 }
  ];

  const handleContinueClick = async () => {
    if (!edit.allFilled('deposit')) {
      return toast.error('Please select any one!');
    }
    let data = {
      ...state?.formData,
      ...edit.edits,
      player_id: localStorage.getItem('playerId')
    };
    const response: any = await API_SERVICES.vendorProfileService.create(
      state?.vendorId,
      { data, successMessage: `${t('TOAST.vendorProfileCreatedSuccessfully')}` }
    );
    if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
      navigateTo('/vendor/vendor-approval', {
        replace: true
      });
    }
  };

  return (
    <Grid container>
      <LoginHeaderComp
        title={t('LOGIN.signUp')}
        subText={'Do you want to Deposit'}
        color={theme.Colors.orangePrimary}
      />
      <UHSelectComp
        initialValue={[edit.getValue('deposit')]}
        labelData={depositData}
        checkColor={theme.Colors.orangePrimary}
        isCheckbox
        isCheck={false}
        handleChangeItem={(selectedVal: number[]) => {
          edit.update({
            deposit: selectedVal[0]
          });
        }}
      />
      <ButtonComp
        buttonText={t('LOGIN.continue')}
        backgroundColor={theme.Colors.orangePrimary}
        btnBorderRadius={theme.MetricsSizes.tiny}
        onClickButton={handleContinueClick}
        style={{ margin: theme.spacing(2, 0) }}
      />
      <TermsAndConditionComp />
    </Grid>
  );
};

export default ChooseDeposit;
