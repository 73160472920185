import React, { createElement, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  makeStyles,
  Grid,
  useTheme,
  alpha,
  Icon,
  Theme,
  DialogProps
} from '@material-ui/core';
import ButtonComp from '../ButtonComp';
import { CONFIRM_MODAL } from 'src/Config/constant';
import * as Icons from '@material-ui/icons';
import { Close } from '@material-ui/icons';
import { t } from 'i18next';

type styleProps = {
  color?: string;
  dialogWidth?: string | number;
};

const useStyles = makeStyles<Theme, styleProps>((theme: Theme) => {
  return {
    title: {
      fontWeight: theme.fontWeight.mediumBold
    },
    description: {
      align: 'center'
    },
    iconBackground: {
      width: '50px',
      height: '50px',
      borderRadius: '50%',
      marginBottom: theme.MetricsSizes.small_xx,
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: (props) =>
        alpha(props.color || theme.Colors.primary, 0.2)
    },
    buttonAlignment: {
      margin: theme.spacing(2)
    },
    dialogPaper: {
      width: (props) => props.dialogWidth || 400,
      padding: theme.spacing(1, 2, 0, 2)
    }
  };
});

type Props = DialogProps & {
  color?: string;
  iconType?: string;
  description?: string;
  title: string;
  open: boolean;
  dialogWidth?: string | number;
  onCancelClick?: () => void;
  onConfirmClick?: () => void;
  isActionBtns?: boolean;
  isCloseIcon?: boolean;
  renderDialogContent?: () => React.ReactNode;
  btnText?: string;
  btnWidth?: string | number;
  btnFontSize?: number;
};

const UHConfirmModal = ({
  color,
  iconType,
  description,
  title,
  open = false,
  dialogWidth,
  onCancelClick,
  onConfirmClick,
  isCloseIcon = false,
  isActionBtns = true,
  renderDialogContent,
  btnText,
  btnWidth,
  btnFontSize,
  ...rest
}: Props) => {
  const theme = useTheme();
  const classes = useStyles({ color, dialogWidth });

  const types = {
    [CONFIRM_MODAL.delete]: {
      icon: 'Delete'
    },
    [CONFIRM_MODAL.publish]: {
      icon: 'PublishOutlined'
    },
    [CONFIRM_MODAL.cancel]: {
      icon: 'CancelOutlined'
    },
    [CONFIRM_MODAL.accept]: {
      icon: 'DoneOutlined'
    },
    [CONFIRM_MODAL.reject]: {
      icon: 'BlockOutlined'
    },
    [CONFIRM_MODAL.moveToDustman]: {
      icon: 'MoveToInbox'
    },
    [CONFIRM_MODAL.enquiry]: {
      icon: 'Call'
    },
    [CONFIRM_MODAL.logout]: {
      icon: 'ExitToApp'
    }
  };

  const renderIcon = () => {
    if (!iconType) {
      return null;
    }
    return (
      <Box display="flex" justifyContent="center" position="relative">
        {isCloseIcon ? (
          <Close
            style={{ position: 'absolute', top: 0, right: 0 }}
            onClick={onCancelClick}
          />
        ) : null}
        <div className={classes.iconBackground}>
          {createElement(Icons[types[iconType].icon], {
            style: { color: color || theme.Colors.primary }
          })}
        </div>
      </Box>
    );
  };

  return (
    <Dialog open={open} classes={{ paper: classes.dialogPaper }} {...rest}>
      <DialogTitle disableTypography>
        {renderIcon()}
        <Typography align="center" variant="h4" className={classes.title}>
          {title}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Typography align="center" variant="h6" className={classes.description}>
          {description}
        </Typography>
        {renderDialogContent ? renderDialogContent() : null}
      </DialogContent>

      <DialogActions className={classes.buttonAlignment}>
        {isActionBtns ? (
          <Grid container direction="row" justifyContent={'space-evenly'}>
            <ButtonComp
              backgroundColor={'transparent'}
              height={35}
              buttonText={t('cancel')}
              buttonFontSize={btnFontSize || theme.MetricsSizes.small_xx}
              buttonTextColor={theme.Colors.black}
              buttonFontWeight={theme.fontWeight.medium}
              btnWidth="95px"
              onClickButton={onCancelClick}
            />
            <ButtonComp
              backgroundColor={color || theme.Colors.primary}
              height={35}
              buttonText={btnText || t('confirm')}
              buttonFontSize={btnFontSize || theme.MetricsSizes.small_xx}
              buttonTextColor={theme.Colors.whitePure}
              buttonFontWeight={theme.fontWeight.medium}
              btnWidth={btnWidth || '95px'}
              onClickButton={onConfirmClick}
            />
          </Grid>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

export default UHConfirmModal;
