import React from 'react';
import { Menu, MenuItem, MenuProps } from '@material-ui/core';

type RowActionsProp = {
  disabled?: boolean;
  renderIcon?: () => React.ReactNode;
  text: string;
  onClick: (val?: any) => void;
};

type RowActionProp = MenuProps & {
  open: boolean;
  selectedActionRow?: any;
  rowActions: RowActionsProp[];
  handleClose: () => void;
  anchorEl: null | HTMLElement;
};
const MenuActionComp = (props: RowActionProp) => {
  const {
    open,
    selectedActionRow,
    rowActions,
    handleClose,
    anchorEl,
    ...rest
  } = props;

  return (
    <div>
      <Menu
        anchorEl={anchorEl}
        onClose={handleClose}
        open={open}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        {...rest}
      >
        {rowActions.map((action, index) => {
          return (
            <MenuItem
              disabled={action?.disabled || false}
              onClick={() => action.onClick(selectedActionRow)}
              key={index}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                {action?.renderIcon && (
                  <div style={{ display: 'flex' }}>{action.renderIcon()}</div>
                )}
                {action.text}
              </div>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default MenuActionComp;
