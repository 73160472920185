import React, { useState } from 'react';
import { HTTP_STATUSES } from 'src/Config/constant';
import { API_SERVICES } from 'src/dustmanUI/Services';
import { VendorAddressData } from 'src/dustmanUI/Services/vendorAddressService';

export type VendorDetails = {
  id: number;
  vendor_id: number;
  language_id: number;
  name: string;
  gst: string;
  full_address: string;
  contact_name: string;
  registered_mobile_number: string;
  landline_number: string;
  email_id: string;
  website: string;
  established_year: string;
  vehicle_owned: string;
  order_management_id: number[];
  status_id: number;
  location: string;
  pincode: string;
  image_url: string;
  user_type: number;
  map_url: string;
  deposit_status: number;
  deposit: number;
  player_id?: string;
};

export interface VendorGetAddressProp extends VendorAddressData {
  vendor_id: number;
  created_at: string;
  updated_at: string;
}

export type VendorInfo = {
  vendorDetails: VendorDetails;
  updateVendorInfo: Awaited<(id: number) => Promise<void>>;
  vendorAddressDetails: VendorGetAddressProp[];
};

const INITIAL_STATE: VendorInfo = {
  vendorDetails: {
    id: 0,
    name: '',
    language_id: 0,
    gst: '',
    map_url: '',
    full_address: '',
    order_management_id: [],
    contact_name: '',
    registered_mobile_number: '',
    landline_number: '',
    email_id: '',
    website: '',
    established_year: '',
    vehicle_owned: '',
    status_id: 0,
    location: '',
    pincode: '',
    image_url: '',
    vendor_id: 0,
    user_type: 0,
    deposit_status: 0,
    deposit: 0,
    player_id: ''
  },
  updateVendorInfo: () => undefined,
  vendorAddressDetails: []
};

export const VendorInfoContext = React.createContext({
  ...INITIAL_STATE
});

type Props = {
  children: any;
};

export const VendorInfoProvider = ({ children }: Props) => {
  const [vendorDetails, setVendorDetails] = useState<VendorDetails>(
    INITIAL_STATE.vendorDetails
  );
  const [vendorAddresses, setVendorAddresses] = useState<
    VendorGetAddressProp[]
  >(INITIAL_STATE.vendorAddressDetails);

  const updateVendorDetails = async (id: number) => {
    if (id === 0) {
      setVendorDetails(INITIAL_STATE.vendorDetails);
      setVendorAddresses(INITIAL_STATE.vendorAddressDetails);
      return;
    }
    const response: any = await Promise.all([
      API_SERVICES.vendorProfileService.getProfileByVendorId(id),
      API_SERVICES.vendorAddressService.getAddress(id)
    ]);
    if (response[0]?.status < HTTP_STATUSES.BAD_REQUEST) {
      if (response[0]?.data?.vendorProfile) {
        setVendorDetails((prev: VendorDetails) => {
          return { ...prev, ...response[0]?.data?.vendorProfile };
        });
      }
    }
    if (response[1]?.status < HTTP_STATUSES.BAD_REQUEST) {
      if (response[1]?.data?.address) {
        setVendorAddresses(response[1]?.data?.address);
      }
    }
  };

  const contextValue = React.useMemo(() => {
    return {
      vendorDetails: vendorDetails,
      updateVendorInfo: updateVendorDetails,
      vendorAddressDetails: vendorAddresses
    };
  }, [vendorDetails, updateVendorDetails, vendorAddresses]);

  return (
    <VendorInfoContext.Provider value={contextValue}>
      {children}
    </VendorInfoContext.Provider>
  );
};
