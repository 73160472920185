export const OrderData = {
  OrderServiceGet: (status_id) => {
    if (status_id === 0) {
      return PendingOrder.pendingOrderGet;
    }
    if (status_id === 2) {
      return ConfirmOrder.ConfirmOrderGet;
    } else {
      return CompletedOrder.CompletedOrderGet;
    }
  }
};

const PendingOrder = {
  pendingOrderGet: {
    status: 200,
    data: {
      orders: [
        {
          id: 1,
          order_type_id: 1,
          order_type: 'Customer Order',
          user_type_id: 3,
          user_type: 'Industry',
          orderer_id: 2,
          customer_id: 2,
          registered_mobile_number: '918270129129',
          first_name: 'surya',
          last_name: 'd',
          email: 'lohi.s@gmail.com',
          quantity_kg: 300,
          description: 'none',
          status_id: 0,
          created_at: '2022-10-14T11:52:08.262Z',
          updated_at: '2022-10-14T11:52:08.262Z',
          order_id: 1,
          vehicle_id: 1,
          vehicle_name: 'Truck',
          pickup_time: '2022-09-13T05:29:10.414Z',
          slot: 'evening',
          address:
            'New No: 103,4th cross street,Ram Nagar,tirunelveli,Tamil Nadu',
          city: 'tirunelveli',
          pincode: '600041',
          order_mobile_number: '9876534893',
          map_location: 'map_url',
          address_order_id: 1,
          order_items: ['Cloth Waste'],
          order_images: ['image1']
        }
      ]
    }
  }
};

const ConfirmOrder = {
  ConfirmOrderGet: {
    status: 200,
    data: {
      orders: [
        {
          id: 1,
          order_type_id: 1,
          order_type: 'Customer Order',
          user_type_id: 3,
          user_type: 'Industry',
          orderer_id: 2,
          customer_id: 2,
          registered_mobile_number: '918270129129',
          first_name: 'surya',
          last_name: 'd',
          email: 'lohi.s@gmail.com',
          quantity_kg: 300,
          description: 'none',
          status_id: 2,
          created_at: '2022-10-14T11:52:08.262Z',
          updated_at: '2022-10-14T11:52:08.262Z',
          order_id: 1,
          vehicle_id: 1,
          vehicle_name: 'Truck',
          pickup_time: '2022-09-13T05:29:10.414Z',
          slot: 'evening',
          address:
            'New No: 103,4th cross street,Ram Nagar,tirunelveli,Tamil Nadu',
          city: 'tirunelveli',
          pincode: '600041',
          order_mobile_number: '9876534893',
          map_location: 'map_url',
          address_order_id: 1,
          order_items: ['Cloth Waste'],
          order_images: ['image1']
        },
        {
          id: 6,
          order_type_id: 1,
          order_type: 'Customer Order',
          user_type_id: 3,
          user_type: 'Industry',
          orderer_id: 2,
          customer_id: 2,
          registered_mobile_number: '918270129129',
          first_name: 'surya',
          last_name: 'd',
          email: 'lohi.s@gmail.com',
          quantity_kg: 5000,
          description: 'none',
          status_id: 2,
          created_at: '2022-10-14T11:54:17.914Z',
          updated_at: '2022-10-14T11:54:17.914Z',
          order_id: 6,
          vehicle_id: 2,
          vehicle_name: 'Tempo',
          pickup_time: '2022-09-13T05:29:10.414Z',
          slot: 'evening',
          address:
            'New No: 103,4th cross street,Ram Nagar,tirunelveli,Tamil Nadu',
          city: 'tirunelveli',
          pincode: '600041',
          order_mobile_number: '9876534893',
          map_location: 'map_url',
          address_order_id: 6,
          order_items: ['Wood Waste'],
          order_images: ['image1', 'image2']
        }
      ]
    }
  }
};

const CompletedOrder = {
  CompletedOrderGet: {
    status: 200,
    data: {
      orders: [
        {
          id: 1,
          order_type_id: 1,
          order_type: 'Customer Order',
          user_type_id: 3,
          user_type: 'Industry',
          orderer_id: 2,
          customer_id: 2,
          registered_mobile_number: '918270129129',
          first_name: 'surya',
          last_name: 'd',
          email: 'lohi.s@gmail.com',
          quantity_kg: 300,
          description: 'none',
          status_id: 3,
          created_at: '2022-10-14T11:52:08.262Z',
          updated_at: '2022-10-14T11:52:08.262Z',
          order_id: 1,
          vehicle_id: 1,
          vehicle_name: 'Truck',
          pickup_time: '2022-09-13T05:29:10.414Z',
          slot: 'evening',
          address:
            'New No: 103,4th cross street,Ram Nagar,tirunelveli,Tamil Nadu',
          city: 'tirunelveli',
          pincode: '600041',
          order_mobile_number: '9876534893',
          map_location: 'map_url',
          address_order_id: 1,
          order_items: ['Cloth Waste'],
          order_images: ['image1']
        },
        {
          id: 4,
          order_type_id: 1,
          order_type: 'Customer Order',
          user_type_id: 3,
          user_type: 'Industry',
          orderer_id: 2,
          customer_id: 2,
          registered_mobile_number: '918270129129',
          first_name: 'surya',
          last_name: 'd',
          email: 'lohi.s@gmail.com',
          quantity_kg: 1400,
          description: 'none',
          status_id: 3,
          created_at: '2022-10-14T11:53:40.426Z',
          updated_at: '2022-10-14T11:53:40.426Z',
          order_id: 4,
          vehicle_id: 3,
          vehicle_name: 'Tata ace',
          pickup_time: '2022-09-13T05:29:10.414Z',
          slot: 'evening',
          address:
            'New No: 103,4th cross street,Ram Nagar,tirunelveli,Tamil Nadu',
          city: 'tirunelveli',
          pincode: '600041',
          order_mobile_number: '9876534893',
          map_location: 'map_url',
          address_order_id: 4,
          order_items: ['e waste', 'plastic'],
          order_images: ['image1', 'image2']
        }
      ]
    }
  }
};
