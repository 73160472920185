import React, { useEffect, useState, useLayoutEffect } from 'react';
import {
  colors,
  Grid,
  makeStyles,
  Theme,
  useTheme,
  Zoom
} from '@material-ui/core';
import {
  DialogComp,
  TextInputComponent,
  ButtonComp,
  Loader
} from 'src/components';
import { useTranslation } from 'react-i18next';
import { useEdit } from 'src/hooks/useEdit';
import toast from 'react-hot-toast';
import {
  isPhoneNumber,
  isValidPinCode,
  isAlphaExp,
  capitalizeFirstLetter
} from 'src/Utils';
import { AddressData } from 'src/dustmanUI/Services/customerAddressService';
import { MAP_URL, GOOGLE_API_KEY, DIALOG_TYPE } from 'src/Config/constant';
import Geocode from 'react-geocode';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import Map from '../UHAddressModalComp/Map';
import Marker from '../UHAddressModalComp/Marker';

const useStyles = makeStyles((theme: Theme) => {
  return {
    dialogPaper: {
      width: 683,
      height: 500,
      padding: theme.spacing(2, 2, 1, 1),
      borderRadius: theme.MetricsSizes.regular
    },
    map: {
      height: 180,
      borderRadius: theme.MetricsSizes.regular
    }
  };
});

type Props = {
  onClose: () => void;
  dialogType?: string;
  handleSaveButtonClick: (
    data: AddressData,
    modalCloseCallback: () => void,
    dialogType: string
  ) => void;
  modalTitle?: string;
  item?: any;
  isCustomerModal?: boolean;
};

const UHAddressModalComp = ({
  onClose,
  handleSaveButtonClick,
  modalTitle,
  item,
  dialogType,
  isCustomerModal = false
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const [isError, setIsError] = useState(false);
  const initialAddressValues: AddressData = {
    id: item?.id || '',
    address_line1: item?.address_line1 || '',
    address_line2: item?.address_line2 || '',
    address_line3: item?.address_line3 || '',
    state: item?.state || '',
    city: item?.city || '',
    pincode: item?.pincode || '',
    mobile_number: item?.mobile_number || '',
    latitude: item?.latitude || '',
    longitude: item?.longitude || '',
    map_url: item?.map_url || ''
  };
  const edit = useEdit(initialAddressValues);
  const RequiredFields = [
    'address_line1',
    'address_line2',
    'address_line3',
    'state',
    'city',
    'pincode',
    'mobile_number'
  ];
  const [loading, setLoading] = useState(true);
  const [zoom, setZoom] = useState(17);
  // const [clicks, setClicks] = useState<google.maps.LatLng[]>([]);
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: 13.113974765565846,
    lng: 80.15234154647385
  });

  const getAddress = async ({
    latitude,
    longitude
  }: {
    latitude: number;
    longitude: number;
  }) => {
    await Geocode.fromLatLng(
      latitude.toString(),
      longitude.toString(),
      GOOGLE_API_KEY
    )
      .then((response: any) => {
        const address = response?.results[0].formatted_address;
        let street: any,
          street_number: any,
          address_line1: any,
          route: any,
          city: any,
          state: any,
          pincode: any,
          neighborhood: any,
          landmark: any;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case 'premise':
                address_line1 =
                  response.results[0].address_components[i].long_name;
                break;
              case 'neighborhood':
                neighborhood =
                  response.results[0].address_components[i].long_name;
                break;
              case 'route':
                route = response.results[0].address_components[i].long_name;
                break;
              case 'street_number':
                street_number =
                  response.results[0].address_components[i].long_name;
                break;
              case 'sublocality_level_1':
                street = response.results[0].address_components[i].long_name;
                break;
              case 'locality':
                city = response.results[0].address_components[i].long_name;
                break;
              case 'administrative_area_level_1':
                state = response.results[0].address_components[i].long_name;
                break;
              case 'postal_code':
                pincode = response.results[0].address_components[i].long_name;
                break;
              case 'landmark':
                landmark = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }

        edit.update({
          address_line1: address_line1,
          address_line2: street || street_number || route || neighborhood,
          address_line3: landmark,
          state: state,
          city: capitalizeFirstLetter(city),
          pincode: pincode,
          latitude: latitude,
          longitude: longitude,
          map_url: MAP_URL + latitude + ',' + longitude + ''
        });
      })
      .catch((error) => {
        toast.error('Coordinates are invalid');
      });
  };

  useEffect(() => {
    if (dialogType === DIALOG_TYPE.edit) {
      const position = {
        lat: parseFloat(item?.latitude),
        lng: parseFloat(item?.longitude)
      };
      setCenter(position);
    } else if (dialogType === DIALOG_TYPE.create) {
      navigator?.geolocation.getCurrentPosition(
        ({ coords: { latitude: lat, longitude: lng } }) => {
          const position = { lat, lng };
          getAddress({ latitude: lat, longitude: lng });
          setCenter(position);
        },
        (posError) => toast.error(posError?.message),
        {
          enableHighAccuracy: true
        }
      );
    }
  }, []);

  const handleOnSaveClick = async () => {
    if (
      !edit.allFilled(...RequiredFields) ||
      !isValidPinCode(edit.getValue('pincode')) ||
      !isPhoneNumber(edit.getValue('mobile_number')) ||
      !isAlphaExp(edit.getValue('state')) ||
      !isAlphaExp(edit.getValue('city'))
    ) {
      setIsError(true);
      return;
    }
    let data = { ...initialAddressValues, ...edit.edits };
    handleSaveButtonClick(data, onClose, dialogType);
  };

  const renderAction = () => {
    return (
      <Grid container justifyContent="center">
        <ButtonComp
          buttonText={t('confirmAddress')}
          backgroundColor={
            isCustomerModal
              ? theme.Colors.secondary
              : theme.Colors.orangePrimary
          }
          btnBorderRadius={theme.MetricsSizes.large_x}
          onClickButton={handleOnSaveClick}
          height={theme.MetricsSizes.large_xx}
          btnWidth={342}
        />
      </Grid>
    );
  };

  // const onClick = (e: google.maps.MapMouseEvent) => {
  //   setClicks([e.latLng!]);
  // };
  const onIdle = (m: google.maps.Map) => {
    let data = m.getCenter()!.toJSON();
    setZoom(m.getZoom()!);
    setCenter(data);
    getAddress({ latitude: data?.lat, longitude: data?.lng });
  };
  const render = (status: Status) => {
    return (
      <h1
        style={{
          color: isCustomerModal
            ? theme.Colors.secondary
            : theme.Colors.orangePrimary
        }}
      >
        {status}
      </h1>
    );
  };

  return (
    <DialogComp
      open={true}
      dialogTitle={modalTitle}
      dialogClasses={{ paper: classes.dialogPaper }}
      onClose={onClose}
      renderAction={renderAction}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} xl={4} className={classes.map}>
          <Wrapper apiKey={GOOGLE_API_KEY} render={render}>
            <Map
              center={center}
              //  onClick={onClick}
              onIdle={onIdle}
              zoom={zoom}
              style={{
                width: '100%',
                height: '100%'
              }}
            >
              <Marker position={center} />
            </Map>
          </Wrapper>
        </Grid>
        <Grid item xs={12}>
          <TextInputComponent
            placeholderText={t('PICKUP.houseNo')}
            textColor={theme.Colors.lightBlack}
            value={edit.getValue('address_line1')}
            onChange={(e) =>
              edit.update({
                address_line1: e.target.value
              })
            }
            inputHeight={theme.MetricsSizes.large_xx}
            helperText={
              isError &&
              !edit.getValue('address_line1') &&
              'Please enter house no'
            }
            isError={isError && !edit.allFilled('address_line1')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInputComponent
            placeholderText={t('PICKUP.area')}
            textColor={theme.Colors.lightBlack}
            value={edit.getValue('address_line2')}
            onChange={(e) =>
              edit.update({
                address_line2: e.target.value
              })
            }
            inputHeight={theme.MetricsSizes.large_xx}
            helperText={
              isError && !edit.getValue('address_line2') && 'Please enter area'
            }
            isError={isError && !edit.allFilled('address_line2')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInputComponent
            placeholderText={t('PICKUP.state')}
            value={edit.getValue('state')}
            onChange={(e) =>
              edit.update({
                state: e.target.value
              })
            }
            textColor={theme.Colors.lightBlack}
            inputHeight={theme.MetricsSizes.large_xx}
            helperText={
              isError &&
              ((!edit.getValue('state') && 'Please enter your state') ||
                (edit.getValue('state') &&
                  !isAlphaExp(edit.getValue('state')) &&
                  'Please enter valid state,should not include "double space,special characters such as "!`@#$%^&*()+=-[]"'))
            }
            isError={
              isError &&
              (!edit.allFilled('state') ||
                (edit.getValue('state') && !isAlphaExp(edit.getValue('state'))))
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextInputComponent
            placeholderText={t('PICKUP.city')}
            value={edit.getValue('city')}
            onChange={(e) =>
              edit.update({
                city: capitalizeFirstLetter(e.target.value)
              })
            }
            textColor={theme.Colors.lightBlack}
            inputHeight={theme.MetricsSizes.large_xx}
            helperText={
              isError &&
              ((!edit.getValue('city') && 'Please enter your city') ||
                (edit.getValue('city') &&
                  !isAlphaExp(edit.getValue('city')) &&
                  'Please enter valid city,should not include "double space,special characters such as "!`@#$%^&*()+=-[]"'))
            }
            isError={
              isError &&
              (!edit.allFilled('city') ||
                (edit.getValue('city') && !isAlphaExp(edit.getValue('city'))))
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextInputComponent
            placeholderText={t('PICKUP.pincode')}
            value={edit.getValue('pincode')}
            textColor={theme.Colors.lightBlack}
            inputHeight={theme.MetricsSizes.large_xx}
            onChange={(e) => {
              if (isNaN(Number(e.target.value))) {
                return;
              }
              edit.update({
                pincode: e.target.value
              });
            }}
            helperText={
              ((isError && !edit.getValue('pincode')) ||
                (isError &&
                  edit.getValue('pincode') &&
                  !isValidPinCode(edit.getValue('pincode')))) &&
              'Please enter your valid pincode'
            }
            isError={
              isError &&
              (!edit.allFilled('pincode') ||
                (edit.getValue('pincode') &&
                  !isValidPinCode(edit.getValue('pincode'))))
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextInputComponent
            placeholderText={t('PICKUP.landmark')}
            value={edit.getValue('address_line3')}
            textColor={theme.Colors.lightBlack}
            inputHeight={theme.MetricsSizes.large_xx}
            onChange={(e) =>
              edit.update({
                address_line3: e.target.value
              })
            }
            helperText={
              isError &&
              !edit.getValue('address_line3') &&
              'Please enter land mark'
            }
            isError={isError && !edit.allFilled('address_line3')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInputComponent
            placeholderText={t('PICKUP.mobileNo')}
            value={edit.getValue('mobile_number')}
            onChange={(e) => {
              if (isNaN(Number(e.target.value))) {
                return;
              }
              edit.update({ mobile_number: e.target.value });
            }}
            textColor={theme.Colors.lightBlack}
            inputHeight={theme.MetricsSizes.large_xx}
            helperText={
              isError &&
              ((!edit.getValue('mobile_number') &&
                'Please enter your mobile_number') ||
                (edit.getValue('mobile_number') &&
                  !isPhoneNumber(edit.getValue('mobile_number')) &&
                  'Please enter your valid 10 digit mobile number'))
            }
            isError={
              isError &&
              (!edit.allFilled('mobile_number') ||
                (edit.getValue('mobile_number') &&
                  !isPhoneNumber(edit.getValue('mobile_number'))))
            }
          />
        </Grid>
      </Grid>
    </DialogComp>
  );
};

export default UHAddressModalComp;
