import { apiOptions } from 'src/Utils/apiOptions';
import { apiRequest } from 'src/Utils/apiRequest';
import { Config } from 'src/Config';
import { DefaultProp } from './customerOrderService';
//import { trashData, vendorDropOrder } from './Stub';
export type CreateCallProp = {
  successMessage?: string;
  failureMessage?: string;
  orderTypeId: number;
  ordererId: number;
};

export interface ReplaceProp extends Omit<DefaultProp, "data"> {
  data:{
    status_id: number;
    orders:number[]
   }
}

export const generalService = {
  getAllVehicles: async () => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getAllVehicles`,
      method: 'get'
    });
    return apiRequest(options);
  },

  getDustmanLocation: async () => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getDustmanLocation`,
      method: 'get'
    });
    return apiRequest(options);
    // return vendorDropOrder.vendorDropGet;
  },

  getAllUserTypes: async () => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getAllUserType`,
      method: 'get'
    });
    return apiRequest(options);
  },

  getAllTrashCategory: async (categoryTypeId: number, userTypeId: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/categoriesWithoutToken/categoryType/${categoryTypeId}/UserType/${userTypeId}/categoryItems/getAllCategory`,
      method: 'get'
    });
    return apiRequest(options);
    //return trashData.trashDataGet;
  },

  createEnquiryCall: async ({
    successMessage,
    failureMessage,
    orderTypeId,
    ordererId
  }: CreateCallProp) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/createEnquiryCall/order_type/${orderTypeId}/orderer/${ordererId}`,
      method: 'post'
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },
  getAllOrderPrice: async () => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getAllOrderPrice`,
      method: 'get'
    });
    return apiRequest(options);
  },

  replaceOrder: async ({
    data,
    successMessage,
    failureMessage
  }: ReplaceProp) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/replaceOrder/order`,
      method: 'put',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },
  getTermsAndCondition: async () => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/termCondition`,
      method: 'get'
    });
    return apiRequest(options);
  },
  getPrivacyPolicy: async () => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/privacyPolicy`,
      method: 'get'
    });
    return apiRequest(options);
  }
};
