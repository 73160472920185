import { useCallback, useEffect, useState } from 'react';
import { Grid, makeStyles, Theme, useTheme } from '@material-ui/core';
import { UHConfirmModal, UHSelectYourPickUpComp } from 'src/components';
import { useTranslation } from 'react-i18next';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => {
  return {
    dialogPaper: {
      width: 783,
      minHeight: 480,
      padding: theme.spacing(4, 2, 5, 2),
      borderRadius: theme.MetricsSizes.regular
    },
    dialogTitleRoot: {
      padding: theme.spacing(0, 2.8)
    }
  };
});

type Props = {
  onClose: () => void;
  orderData?: any;
  color?: string;
  onRescheduleConfirmClick?: (orderData: any) => void;
  updateSelectedDate: () => void;
  onRescheduleClick: () => void;
  activeButtonColor?: string;
  activeTileColor?: string;
};

const RescheduleOrderModal = (props: Props) => {
  const {
    onClose,
    orderData,
    updateSelectedDate,
    onRescheduleConfirmClick,
    color,
    activeTileColor,
    activeButtonColor
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();

  const renderDialogContent = () => {
    return (
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <UHSelectYourPickUpComp
            updateSelectedDate={updateSelectedDate}
            activeTileColor={activeTileColor}
            activeButtonColor={activeButtonColor}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <UHConfirmModal
      open
      title={`${t('order')} ${orderData?.id} `}
      onCancelClick={onClose}
      onConfirmClick={() => onRescheduleConfirmClick(orderData)}
      renderDialogContent={renderDialogContent}
      btnText={t('confirm')}
      color={color}
      btnWidth="135px"
      btnFontSize={theme.MetricsSizes.small}
      maxWidth="md"
      dialogWidth={750}
    />
  );
};

export default React.memo(RescheduleOrderModal);
