import { apiOptions } from 'src/Utils/apiOptions';
import { apiRequest } from 'src/Utils/apiRequest';
import { Config } from 'src/Config';
import { DefaultProp } from './customerOrderService';
import { CustomerAddressData } from './Stub';

export type AddressData = {
  id: number;
  address_line1: string;
  address_line2: string;
  address_line3: string;
  state: string;
  city: string;
  pincode: string;
  mobile_number: string;
  latitude: string;
  longitude: string;
  map_url: string;
};

export interface CustomerGetAddressDataProp extends AddressData {
  customer_id: number;
  is_available: boolean;
  created_at: string;
  updated_at: string;
}

type AddressCreateProp = {
  data: AddressData;
  successMessage?: string;
  failureMessage?: string;
};

export const customerAddressService = {
  create: async (
    customerId: number,
    { data, successMessage, failureMessage }: AddressCreateProp
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/createContact/customer/${customerId}`,
      method: 'post',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
    //return CustomerAddressData.CustomerAddressCreate;
  },

  replaceAddress: async (
    id: number,
    { data, successMessage, failureMessage }: DefaultProp
  ) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/replaceContact/customer_contact/${id}`,
      method: 'put',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },

  getAddress: async (id: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getAllCustomerContacts/customer/${id}`,
      method: 'get'
    });
    return apiRequest(options);
  }
};
