import React, { useState } from 'react';
import { Grid, makeStyles, Theme, useTheme } from '@material-ui/core';
import AddressDisplay from './AddressDisplay';
import OrdersPages from 'src/dustmanUI/VendorContent/OrdersPage';
import { BookYourPageImage } from 'src/Assets/Images';
import { EnquiryComp, UHConfirmModal, UHTabComponent } from 'src/components';
import { CONFIRM_MODAL, HTTP_STATUSES, ORIENTATION } from 'src/Config/constant';
import CustomerOrdersPage from 'src/dustmanUI/VendorContent/CustomerOrdersPage';
import BookMyPickup from 'src/dustmanUI/VendorContent/BookMyPickUp';
import BookMyDrop from '../BookMyDrop';
import VendorProfile from '../VendorProfile';
import { t } from 'i18next';
import { API_SERVICES } from 'src/dustmanUI/Services';
import useVendorInfo from 'src/hooks/useVendorInfo';
import toast from 'react-hot-toast';

const useStyles = makeStyles((theme: Theme) => ({
  superOuterContainer: {
    background: '#E8E8E8',
    //   'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(14.66deg, #F68B1F -27.51%, #FFFFFF 54.57%)',
    width: '100%',
    padding: theme.spacing(3, 12, 12, 16)
  },
  imageContainer: {
    paddingLeft: theme.spacing(8),
    position: 'sticky',
    top: 0,
    height: '100%'
  },
  addressContainer: {
    padding: theme.spacing(0, 0, 1, 0)
  },
  tabContainer: {
    backgroundColor: 'transparent',
    width: '100%'
  },
  tabRoot: {
    fontSize: theme.MetricsSizes.small_xxx,
    color: theme.Colors.mediumBlack,
    fontWeight: theme.fontWeight.regular
  },
  selectedTab: {
    fontWeight: theme.fontWeight.bold,
    color: theme.Colors.orangePrimary
  }
}));

function VendorHomePage() {
  const classes = useStyles();
  const theme = useTheme();
  const [confirmModal, setConfirmModal] = useState<any>({ open: false });
  const { vendorDetails } = useVendorInfo();
  const tabItems = [
    {
      id: 1,
      label: t('customerOrders'),
      component: () => <CustomerOrdersPage />
    },
    { id: 2, label: t('VENDORHOME.myOrder'), component: () => <OrdersPages /> },
    {
      id: 3,
      label: t('VENDORHOME.bookMyPickup'),
      component: () => <BookMyPickup  onTabChange={onTabChange} />
    },
    {
      id: 4,
      label: t('VENDORHOME.bookMyDrop'),
      component: () => <BookMyDrop onTabChange={onTabChange} />
    },
    {
      id: 5,
      label: t('VENDORHOME.profile'),
      component: () => <VendorProfile />
    }
  ];
  const [selectedTab, setSelectedTab] = useState(tabItems[0]?.id);

  const onTabChange = (value: any) => {
    setSelectedTab(value);
  };
  const onCancelClick = () => {
    setConfirmModal(false);
  };
  let confirmModalProps = {
    color: theme.Colors.primary,
    // description: t('customerOrder.cancelCustomerOrder'),
    // title: t('customerOrder.cancel'),
    description: `${t('pleaseWaitDustmanContactShortly')}`,
    title: `${t('enquiryCall')}`,
    iconType: CONFIRM_MODAL.enquiry,
    isCloseIcon: true,
    isActionBtns: false
  };
  const handleButtonClick = async () => {
    try {
      const response: any = await API_SERVICES.generalService.createEnquiryCall(
        {
          orderTypeId: 2,
          ordererId: vendorDetails?.vendor_id,
          // successMessage: 'Customer Enquiry Created Successfully',
          failureMessage: 'Something went wrong!. Please try again later '
        }
      );
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        setConfirmModal({ open: true, onCancelClick, ...confirmModalProps });
      }
    } catch (err) {
      toast.error(err?.message);
    }
  };

  const renderTabContent = (tabVal?: any) => {
    const findActiveTab = tabItems.find(({ id }) => id === tabVal);
    return <Grid>{findActiveTab ? findActiveTab.component() : null}</Grid>;
  };

  return (
    <Grid container className={classes.superOuterContainer}>
      <Grid item container xs={12} className={classes.addressContainer}>
        <Grid item xs>
          <AddressDisplay />
        </Grid>
        <Grid item>
          <EnquiryComp
            buttonTextColor={theme.Colors.orangePrimary}
            backgroundCircleColor={theme.Colors.orangePrimary}
            handleButtonClick={handleButtonClick}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={
          selectedTab === tabItems[2]?.id || selectedTab === tabItems[3]?.id
            ? 8
            : 12
        }
      >
        <UHTabComponent
          currentTabVal={selectedTab}
          tabContent={tabItems}
          orientation={ORIENTATION.HORIZONTAL}
          tabIndicatorColor={theme.Colors.orangePrimary}
          tabContainerClassName={classes.tabContainer}
          renderTabContent={renderTabContent}
          onTabChange={onTabChange}
          variant="scrollable"
          scrollButtons="auto"
          tabClasses={{
            root: classes.tabRoot,
            selected: classes.selectedTab
          }}
        />
      </Grid>
      {(selectedTab === tabItems[2]?.id || selectedTab === tabItems[3]?.id) && (
        <Grid item xs className={classes.imageContainer}>
          <img src={BookYourPageImage} alt="Image" />
        </Grid>
      )}
      {confirmModal.open && <UHConfirmModal {...confirmModal} />}
    </Grid>
  );
}

export default VendorHomePage;
