import { useTheme, Grid } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import {
  ButtonComp,
  LoginHeaderComp,
  TextInputComponent
} from 'src/components';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { TermsAndConditionComp } from './TermsAndConditionComp';
import { useEdit } from 'src/hooks/useEdit';
import { isValidEmail, isValidPinCode, capitalizeFirstLetter } from 'src/Utils';
import { useEffect, useState } from 'react';
import useUserInfo from 'src/hooks/useUserInfo';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(5)
  }
}));

const CreateAccountSignUp1 = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigateTo = useNavigate();
  const [isError, setIsError] = useState(false);
  const { state }: any = useLocation();
  const [customerDetails, setCustomerDetails] = useState(
    state?.isFromInitialForm
      ? {
          customer_id: state?.customerId,
          user_type_id: state?.isFromInitialForm
        }
      : {
          customer_id: state?.customerId,
          user_type_id: 1
        }
  );
  const initialValues = {
    contact_name: '',
    company_name: '',
    customer_gst: '',
    email: '',
    image_url: ''
    /*     location: '',
    pincode: '',
    map_url: '',
    address_line1: '',
    address_line2: '',
    address_line3: '',
    latitude: '',
    longitude: '' */
  };
  const edit = useEdit(
    state?.formEdits && !state?.isFromInitialForm
      ? state?.formEdits
      : initialValues
  );


  const pickupAddressInitialValue = {
    location: '',
    pincode: '',
    map_url: '',
    address_line1: '',
    address_line2: '',
    address_line3: '',
    latitude: '',
    longitude: ''
  };
  const editPickupAddress = useEdit(
    state?.pickupAddressEdit
      ? state?.pickupAddressEdit
      : pickupAddressInitialValue
  );
  const registeredAddressInitialValue = {
    location: '',
    pincode: '',
    map_url: '',
    address_line1: '',
    address_line2: '',
    address_line3: '',
    latitude: '',
    longitude: ''
  };
 

  const editRegisteredAddress = useEdit(
    state?.registeredAddressEdit
      ? state?.registeredAddressEdit
      : registeredAddressInitialValue
  );

  const RequiredFields = [
    'contact_name',
    'company_name',
    'pincode',
    'location'
  ];

  const handleContinueClick = () => {
    console.log('All Data', {
      ...edit.edits,     
     // ...state?.formEdits,
      ...customerDetails
    },"State",state);
    
    if (
      !edit.allFilled(...RequiredFields) ||
      !isValidEmail(edit.getValue('email')) ||
      !isValidPinCode(edit.getValue('pincode'))
    ) {
      setIsError(true);
      return;
    }
     /* let data = {
      ...state?.formEdits,
      ...customerDetails,
      ...state?.registeredAddressEdit,
      player_id: localStorage.getItem('playerId')
    };
    const response: any = await API_SERVICES.customerProfileService.create(
      customerDetails?.customer_id,
      {
        data,
        successMessage: `${t('TOAST.customerProfileCreatedSuccessfully')}`
      }
    );
    if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
      if (response?.data?.customerProfile?.customer_id) {
        updateUserInfo(response.data.customerProfile.customer_id);
        setCustomerId(response.data.customerProfile.customer_id);
        navigateTo('/customer-home', { replace: true });
      }
    } */
     
  };
  useEffect(() => {
  });
  return (
    <Grid>
      <LoginHeaderComp
        title={t('LOGIN.signUp')}
        subText={t('LOGIN.userProfile')}
      />
      <Grid className={classes.container}>
        <TextInputComponent
          inputHeight={66}
          placeholderText={t('LOGIN.contactName')}
          value={edit.getValue('contact_name')}
          onChange={(e) => edit.update({ contact_name: e.target.value })}
          inputBorderRadius={0}
          textColor={theme.Colors.primary}
          helperText={
            isError &&
            !edit.allFilled('contact_name') &&
            'Please Enter your contact name'
          }
        />
        <TextInputComponent
          inputHeight={66}
          placeholderText={t('LOGIN.companyName')}
          value={edit.getValue('company_name')}
          onChange={(e) => edit.update({ company_name: e.target.value })}
          inputBorderRadius={0}
          textColor={theme.Colors.primary}
          helperText={
            isError &&
            !edit.allFilled('company_name') &&
            'Please Enter your Company name'
          }
        />
        <TextInputComponent
          inputHeight={66}
          placeholderText={t('LOGIN.gstNumber')}
          value={edit.getValue('customer_gst')}
          onChange={(e) => edit.update({ customer_gst: e.target.value })}
          inputBorderRadius={0}
          textColor={theme.Colors.primary}
          /*           helperText={
            isError &&
            !edit.allFilled('gst_number') &&
            'Please Enter your last name'
          } */
        />
        <TextInputComponent
          inputHeight={66}
          placeholderText={t('LOGIN.email')}
          value={edit.getValue('email')}
          onChange={(e) => edit.update({ email: e.target.value })}
          inputBorderRadius={0}
          textColor={theme.Colors.primary}
        />
        <TextInputComponent
          inputHeight={66}
          placeholderText={t('LOGIN.registeredAddress')}
          value={state?.registeredAddress?.registered_address}
          onFocus={() =>
            navigateTo(`/customer/enter-address-location`, {
              replace: false,
              state: {
                formEdits: { ...state?.formEdits, ...edit.edits },
                customerId: state?.customerId,
                pickupAddress: state?.pickupAddress,
                pickupAddressEdit: state?.pickupAddressEdit,
                registeredAddress: state?.registeredAddress,
                registeredAddressEdit: editRegisteredAddress?.edits
              }
            })
          }
          inputBorderRadius={0}
          textColor={theme.Colors.primary}
        />
        <TextInputComponent
          inputHeight={66}
          placeholderText={t('LOGIN.pickupAddress')}
          inputBorderRadius={0}
          textColor={theme.Colors.primary}
          value={state?.pickupAddress?.pickup_address}
          onFocus={() => {
            navigateTo(`/customer/enter-address-location`, {
              replace: false,
              state: {
                formEdits: { ...state?.formEdits, ...edit.edits },
                customerId: state?.customerId,
                pickupAddressNeeded: true,
                registeredAddress: state?.registeredAddress,
                registeredAddressEdit: state?.registeredAddressEdit,
                pickupAddress: state?.pickupAddress,
                pickupAddressEdit: editPickupAddress?.edits
              }
            });
          }}
        />
        <ButtonComp
          buttonText={t('LOGIN.continue')}
          backgroundColor={theme.Colors.secondary}
          btnBorderRadius={theme.MetricsSizes.tiny}
          onClickButton={handleContinueClick}
          style={{ margin: theme.spacing(2, 0) }}
        />
        <TermsAndConditionComp />
      </Grid>
    </Grid>
  );
};

export default CreateAccountSignUp1;
