import {
  Grid,
  InputAdornment,
  makeStyles,
  Theme,
  Typography,
  useTheme
} from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInputComponent, UHConfirmModal } from 'src/components';
import OTPInput from 'otp-input-react';

import { useEdit } from 'src/hooks/useEdit';
import { HTTP_STATUSES } from 'src/Config/constant';
import { toast } from 'react-hot-toast';
import { isOneTimePassWord } from 'src/Utils';
import { API_SERVICES } from 'src/dustmanUI/Services';

const useStyles = makeStyles((theme: Theme) => ({
  inputOtpStyle: {
    background: 'transparent',
    border: 'none',
    borderBottom: '2px solid black',
    fontWeight: theme.fontWeight.mediumBold,
    fontSize: '16px'
  },
  titleStyle: {
    padding: theme.spacing(2, 2, 2, 0),
    fontWeight: theme.fontWeight.medium,
    fontSize: theme.MetricsSizes.small_xxx,
    color: theme.Colors.primary
  }
}));

type Props = {
  open?: boolean;
  onClose?: () => void;
  updateData?: () => void;
  orderData?: any;
};

const GenerateOtp = (props: Props) => {
  const { open, onClose, orderData, updateData } = props;
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const [isOtpGenerate, setIsOtpGenerate] = useState<boolean>(false);
  const initialValues = {
    quantity_kg: '',
    price: '',
    otp: ''
  };
  const edit = useEdit(initialValues);

  const handleSave = async () => {
    try {
      let pData = { ...initialValues, ...edit.edits };
      if (isOtpGenerate) {
        if (
          !edit.allFilled('otp') ||
          !isOneTimePassWord(edit.getValue('otp'))
        ) {
          toast.error(`${t('TOAST.pleaseEnterValid6digitotp')}`);
          return;
        }
        const response: any =
          await API_SERVICES.verifyOtpToCompleteTheOrder.update(orderData?.id, {
            data: { otp: pData?.otp },
            successMessage: `${t('TOAST.orderMovedtoCompletedSuccessfully')}`
          });
        if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
          updateData();
          onClose();
        }
      } else {
        if (!edit.allFilled('quantity_kg') && !edit.allFilled('price')) {
          toast.error(
            `${t('TOAST.pleaseEnterthetrashWeightinKgsandTrashPrice')}`
          );
          return;
        }
        const response: any =
          await API_SERVICES.verifyOtpToCompleteTheOrder.create(orderData?.id, {
            data: {
              quantity_kg: Number(pData?.quantity_kg),
              price: pData?.price
            },
            successMessage: `${t('TOAST.otpSentSuccessfully')}`
          });
        if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
          setIsOtpGenerate(true);
        }
      }
    } catch (err) {
      toast.error(err?.message);
    }
  };

  const renderDialogContent = () => {
    return (
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <TextInputComponent
            inputLabel={t('enterTrashWeight')}
            placeholderText={t('enterTrashWeight')}
            labelColor={theme.Colors.primary}
            value={edit.getValue('quantity_kg')}
            onChange={(e) => {
              if (isNaN(Number(e.target.value))) {
                return;
              }
              edit.update({ quantity_kg: e.target.value });
            }}
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">{'Kgs.'}</InputAdornment>
              )
            }}
            disabled={isOtpGenerate}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInputComponent
            inputLabel={t('enterTrashPrice')}
            placeholderText={t('enterTrashPrice')}
            labelColor={theme.Colors.primary}
            value={edit.getValue('price')}
            onChange={(e) => {
              if (isNaN(Number(e.target.value))) {
                return;
              }
              edit.update({ price: e.target.value });
            }}
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">{'Rs.'}</InputAdornment>
              )
            }}
            disabled={isOtpGenerate}
          />
        </Grid>
        {isOtpGenerate ? (
          <Grid item xs={12}>
            <Typography className={classes.titleStyle}>Enter Otp</Typography>
            <OTPInput
              OTPLength={6}
              otpType="number"
              autoFocus={false}
              inputClassName={classes.inputOtpStyle}
              value={edit.getValue('otp')}
              onChange={(val: any) => {
                edit.update({ otp: val });
              }}
            />
          </Grid>
        ) : null}
      </Grid>
    );
  };

  return (
    <UHConfirmModal
      open
      title={t('completeOrder')}
      onCancelClick={onClose}
      onConfirmClick={handleSave}
      renderDialogContent={renderDialogContent}
      btnText={isOtpGenerate ? t('confirm') : t('ORDER.generateOtp')}
      color={theme.Colors.orangePrimary}
      btnWidth="135px"
      btnFontSize={theme.MetricsSizes.small}
    />
  );
};

export default GenerateOtp;
