import React, { useCallback, useEffect, useState } from 'react';
import { Theme, useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import VendorOrderDialog from './vendorOrderDialog';
import { API_SERVICES } from 'src/dustmanUI/Services';
import { useTranslation } from 'react-i18next';
import useVendorInfo from 'src/hooks/useVendorInfo';
import {
  CONFIRM_MODAL,
  CUSTOMER_ORDER_STATUS,
  HTTP_STATUSES,
  ORIENTATION
} from 'src/Config/constant';
import {
  ButtonComp,
  Loader,
  UHConfirmModal,
  UHOrderPreviewComp,
  UHTabComponent
} from 'src/components';
import {
  confirmVendor,
  YetToConfirm,
  pending,
  Completed,
  confirmed,
  confirmedWhite,
  pendingWhite,
  completedWhite
} from 'src/Assets';

import toast from 'react-hot-toast';
import { getDateFormat } from 'src/Utils';
import CheckOutScreen from './CheckOutScreen';
import GenerateOtp from './GenerateOtp';

const useStyles = makeStyles((theme: Theme) => ({
  outerContainer: {
    margin: theme.spacing(1.75, 0, 1.75, 0),
    padding: theme.spacing(6.5, 3, 6.5, 3),
    background: theme.Colors.whitePure,
    position: 'relative'
  },
  tabContainer: {
    border: '0.5px solid',
    borderColor: theme.Colors.greyDark,
    paddingTop: theme.spacing(4)
  },
  tabContentStyle: {
    height: 513,
    overflowY: 'scroll'
  },
  tabContentContainer: {
    margin: theme.spacing(0, 2)
  },
  selectedTabStyle: {
    color: theme.Colors.whitePure,
    background: theme.Colors.orangePrimary,
    fontWeight: theme.fontWeight.medium
  },
  buttonContainer: {
    position: 'absolute',
    left: '50%',
    bottom: 28
  }
}));

function OrdersPage() {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { vendorDetails } = useVendorInfo();
  const [selectedTab, setSelectedTab] = useState<number>(
    CUSTOMER_ORDER_STATUS.New
  );
  const [orderDetails, setOrderDetails] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [modalOpen, setModalOpen] = useState<any>({ open: false });
  const [confirmModal, setConfirmModal] = useState<any>({
    open: false
  });
  const [checkOutOpen, setCheckOutOpen] = useState<any>({ open: false });
  const [orderItems, setOrderItems] = useState([]);
  const [customerOrderPrice, setCustomerOrderPrice] = useState<number>();
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [openModal, setOpenModal] = useState<any>({ open: false });

  const onClickViewDetails = (orderData: any) => {
    setModalOpen({
      open: true,
      orderData: orderData
    });
  };

  const unSelected = (ids: number[]) => {
    let items: any[] = ids.map((ele: number) => {
      return orderItems.filter((item: { id: number }) => {
        return item.id === ele;
      })[0];
    });
    return setOrderItems(items);
  };

  const handleCheckboxChange = (orderData: any, checked: boolean) => {
    let selectedId = selectedIds.filter((id) => orderData?.id !== id);
    if (checked) {
      setOrderItems((prev) => [...prev, orderData]);
      setSelectedIds((prev) => [...prev, orderData.id]);
    } else {
      setSelectedIds(selectedId);
      unSelected(selectedId);
    }
  };

  const OrdersTabItems = [
    {
      tabIcon: () =>
        selectedTab == CUSTOMER_ORDER_STATUS.New ? (
          <img src={pendingWhite} />
        ) : (
          <img src={pending} />
        ),
      label: t('ORDER.new'),
      value: CUSTOMER_ORDER_STATUS.New
    },
    {
      tabIcon: () =>
        selectedTab == CUSTOMER_ORDER_STATUS.Confirmed ? (
          <img src={confirmedWhite} />
        ) : (
          <img src={confirmed} />
        ),
      label: t('ORDER.scheduled'),
      value: CUSTOMER_ORDER_STATUS.Confirmed
    },
    {
      tabIcon: () =>
        selectedTab == CUSTOMER_ORDER_STATUS.Completed ? (
          <img src={completedWhite} />
        ) : (
          <img src={Completed} />
        ),
      label: t('ORDER.completed'),
      value: CUSTOMER_ORDER_STATUS.Completed
    }
  ];

  const fetchData = useCallback(async () => {
    try {
      if (vendorDetails?.vendor_id === 0) {
        return;
      }
      setOrderDetails([]);
      let response: any;
      if (selectedTab === CUSTOMER_ORDER_STATUS.New) {
        response =
          await API_SERVICES.vendorCustomerOrderService.getCustomerOrderByVendorLocation(
            vendorDetails?.vendor_id
          );
      } else if (selectedTab === CUSTOMER_ORDER_STATUS.Confirmed) {
        response =
          await API_SERVICES.vendorCustomerOrderService.getAllVendorScheduledOrder(
            vendorDetails?.vendor_id
          );
      } else if (selectedTab === CUSTOMER_ORDER_STATUS.Completed) {
        response =
          await API_SERVICES.vendorCustomerOrderService.getAllVendorCompletedOrder(
            vendorDetails?.vendor_id
          );
      }

      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (response?.data?.orders) {
          setOrderDetails(response.data.orders);
        }
      }
      let customerOrderPerPrice: any =
        await API_SERVICES.generalService.getAllOrderPrice();

      if (customerOrderPerPrice?.status <= HTTP_STATUSES.BAD_REQUEST) {
        if (customerOrderPerPrice?.data[0].order_price) {
          setCustomerOrderPrice(customerOrderPerPrice?.data[0].order_price);
        }
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  }, [selectedTab, vendorDetails?.vendor_id]);

  const onCancelOrderButton = (orderData: any) => {
    const onCancelClick = () => {
      setConfirmModal({ open: false });
    };
    const onConfirmClick = async () => {
      let updateData = {
        status_id: 4,
        orders: [orderData?.order_id]
      };
      const response: any = await API_SERVICES.generalService.replaceOrder({
        data: updateData,
        successMessage: `${t('TOAST.orderCancelledSuccessfully')}`
      });
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        onCancelClick();
        setModalOpen({ open: false });
        fetchData();
      }
    };
    let props = {
      color: theme.Colors.redPrimary,
      description: t('ORDER.cancelCustomerOrder'),
      title: t('cancelOrder'),
      iconType: CONFIRM_MODAL.cancel
    };
    setConfirmModal({ open: true, onConfirmClick, onCancelClick, ...props });
  };

  const onClickBuyOrderButton = useCallback(async (orderIds?: number[]) => {
    try {
      let orderData = { buyedOrderList: orderIds };
      // const createOrderRes: any =
      //   await API_SERVICES.vendorCustomerOrderService.createVendorBuyingOrder({
      //     data: orderData
      //   });
      // if (createOrderRes?.status < HTTP_STATUSES.BAD_REQUEST) {
      //   if (createOrderRes?.data?.data) {
      //     let amount = createOrderRes?.data?.data?.amount * 100;
      //     let order_id = createOrderRes?.data?.data?.id;
      //     const options = {
      //       //  image: '',
      //       currency: 'INR',
      //       key: 'rzp_test_k1Lam97LxY75uo',
      //       amount: amount.toString(),
      //       name: 'Dustman',
      //       order_id: order_id,
      //       theme: { color: theme.Colors.orangePrimary },
      //       handler: async (response: any) => {
      //         if (Object.keys(response).length) {
      //           const orderData = {
      //             buyedOrderList: orderIds,
      //             paymentDetails: {
      //               razorpay_order_id: response?.razorpay_order_id,
      //               razorpay_payment_id: response?.razorpay_payment_id,
      //               razorpay_signature: response?.razorpay_signature
      //             }
      //           };
      //           const paymentVerifySignatureRes: any =
      //             await API_SERVICES.vendorCustomerOrderService.paymentVerifySignature(
      //               {
      //                 data: orderData,
      //                 vendorId: vendorDetails?.vendor_id
      //               }
      //             );
      //           if (
      //             paymentVerifySignatureRes?.status === HTTP_STATUSES.SUCCESS
      //           ) {
      //             setCheckOutOpen({ open: false });
      //             setSelectedTab(CUSTOMER_ORDER_STATUS.Confirmed);
      //             alert(t('TOAST.yourPaymentwasmadeGoScheduled'));
      //           }
      //         }
      //       }
      //     };
      //     let razorPay = new (window as any).Razorpay(options);
      //     razorPay.open();
      //   }
      // }
      const paymentVerifySignatureRes: any =
        await API_SERVICES.vendorCustomerOrderService.paymentVerifySignature({
          data: orderData,
          // vendorId: vendorDetails?.vendor_id
          vendorId: parseInt(localStorage.getItem('vendorId'))
        });
      if (paymentVerifySignatureRes?.status === HTTP_STATUSES.SUCCESS) {
        setCheckOutOpen({ open: false });
        setSelectedTab(CUSTOMER_ORDER_STATUS.Confirmed);
        alert(t('TOAST.yourPaymentwasmadeGoScheduled'));
      }
    } catch (err) {
      toast.error(err?.message);
    }
  }, []);

  const onTabChange = (tabValue: any) => {
    if (checkOutOpen.open) {
      setCheckOutOpen({
        open: false
      });
    }
    setSelectedTab(tabValue);
  };

  useEffect(() => {
    fetchData();
     const interval = setInterval(() => {
       fetchData();
     }, 60000);
     return () => {
        clearInterval(interval);
     };
    
  }, [fetchData]);

  const handleButtonClick = useCallback(
    (orderData: any = {}) => {
      if (Object.keys(orderData).length) {
        setCheckOutOpen({
          open: true,
          orderItems: [orderData],
          selectedIds: [orderData?.id]
        });
        return;
      }
      if (!orderItems.length || !selectedIds.length) {
        toast.error(t('TOAST.pleaseSelectCustomerOrderBuying'));
        return;
      }
      setCheckOutOpen({
        open: true,
        orderItems: orderItems,
        selectedIds: selectedIds
      });
    },
    [orderItems, selectedIds]
  );

  const handleOtpGenerate = (orderData: any = {}) => {
    setOpenModal({ open: true, orderData: orderData });
  };

  const renderTabContent = () => {
    return (
      <Grid className={classes.tabContentContainer}>
        {checkOutOpen.open ? (
          <CheckOutScreen
            handleCheckOutClick={onClickBuyOrderButton}
            customerOrderPrice={customerOrderPrice}
            {...checkOutOpen}
          />
        ) : (
          <Grid>
            {orderDetails?.length ? (
              orderDetails.map((item, index) => {
                const { getTime, getDateString } = getDateFormat(
                  item?.pickup_time
                );
                return (
                  <UHOrderPreviewComp
                    key={index}
                    orderItems={item}
                    isButtonOne={
                      selectedTab !== CUSTOMER_ORDER_STATUS.Completed
                    }
                    isButtonTwo={
                      selectedTab !== CUSTOMER_ORDER_STATUS.Completed
                    }
                    isCheckBox={selectedTab === CUSTOMER_ORDER_STATUS.New}
                    isButtonThree={
                      selectedTab === CUSTOMER_ORDER_STATUS.Confirmed
                    }
                    handleClickButtonOne={onClickViewDetails}
                    handleClickButtonTwo={
                      selectedTab === CUSTOMER_ORDER_STATUS.New
                        ? handleButtonClick
                        : onCancelOrderButton
                    }
                    handleClickButtonThree={
                      selectedTab === CUSTOMER_ORDER_STATUS.Confirmed
                        ? handleOtpGenerate
                        : onCancelOrderButton
                    }
                    buttonThreeText={
                      selectedTab === CUSTOMER_ORDER_STATUS.Confirmed &&
                      t('ORDER.generateOtp')
                    }
                    orderStatusText={
                      (selectedTab === CUSTOMER_ORDER_STATUS.Confirmed &&
                        `Scheduled on ${getDateString}, ${getTime}`) ||
                      (selectedTab == CUSTOMER_ORDER_STATUS.Completed &&
                        `Completed on ${getDateString}, ${getTime}`)
                    }
                    statusIcon={
                      selectedTab === CUSTOMER_ORDER_STATUS.Pending
                        ? YetToConfirm
                        : confirmVendor
                    }
                    buttonTwoText={
                      selectedTab === CUSTOMER_ORDER_STATUS.New
                        ? t('ORDER.buyOrder')
                        : t('ORDER.cancelButton')
                    }
                    buttonTwoStyle={{
                      background: theme.Colors.orangePrimary,
                      width: 100
                    }}
                    buttonOneStyle={{
                      border: '0.5px solid',
                      borderColor: theme.Colors.orangePrimary,
                      color: theme.Colors.orangePrimary
                    }}
                    buttonThreeStyle={{
                      background: theme.Colors.orangePrimary,
                      width: 120
                    }}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                );
              })
            ) : (
              <Typography variant="body2">NO ORDERS FOUND!</Typography>
            )}
          </Grid>
        )}
      </Grid>
    );
  };

  if (loading) {
    return <Loader />;
  } else {
    return (
      <>
        <Grid className={classes.outerContainer}>
          <UHTabComponent
            currentTabVal={selectedTab}
            tabContent={OrdersTabItems}
            orientation={ORIENTATION.VERTICAL}
            tabClasses={{ selected: classes.selectedTabStyle }}
            tabIndicatorColor={theme.Colors.primary}
            isDivider={false}
            tabContainerClassName={classes.tabContainer}
            renderTabContent={renderTabContent}
            tabContentClassName={classes.tabContentStyle}
            onTabChange={onTabChange}
          />
          <Grid className={classes.buttonContainer}>
            {selectedTab === CUSTOMER_ORDER_STATUS.New && !checkOutOpen.open ? (
              <ButtonComp
                buttonText={t('ORDER.buyAllOrder')}
                buttonFontSize={theme.MetricsSizes.small_xxx}
                backgroundColor={theme.Colors.orangePrimary}
                btnBorderRadius={105}
                height={'48px'}
                btnWidth={'217px'}
                onClickButton={() => handleButtonClick()}
              />
            ) : null}
          </Grid>
        </Grid>
        {modalOpen.open && (
          <VendorOrderDialog
            onClose={() => setModalOpen({ open: false })}
            {...modalOpen}
            selectedTab={selectedTab}
            onCancelButtonClick={onCancelOrderButton}
          />
        )}
        {confirmModal.open && <UHConfirmModal {...confirmModal} />}
        {openModal.open && (
          <GenerateOtp
            {...openModal}
            onClose={() => setOpenModal({ open: false })}
            updateData={fetchData}
          />
        )}
      </>
    );
  }
}

export default OrdersPage;
