import { useTheme, Grid } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import {
  ButtonComp,
  LoginHeaderComp,
  TextInputComponent
} from 'src/components';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { TermsAndConditionComp } from './TermsAndConditionComp';
import { useEdit } from 'src/hooks/useEdit';
import { isValidEmail, isValidPinCode, capitalizeFirstLetter } from 'src/Utils';
import { useEffect, useState } from 'react';
import { MAP_URL, GOOGLE_API_KEY, DIALOG_TYPE } from 'src/Config/constant';
import Geocode from 'react-geocode';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import Map from 'src/components/UHAddressModalComp/Map';
import Marker from 'src/components/UHAddressModalComp/Marker';
import toast from 'react-hot-toast';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { PlaceMaker } from 'src/Assets';
import useUserInfo from 'src/hooks/useUserInfo';
import { initialValues } from './../../VendorContent/BookMyPickUp/index';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    paddingBottom: theme.spacing(5)
  },
  map: {
    height: 180,
    borderRadius: theme.MetricsSizes.regular
  },
  textFieldContainer: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5)
  },
  textStyles: {
    fontSize: theme.MetricsSizes.regular,
    fontWeight: theme.fontWeight.medium,
    color: theme.Colors.secondary
  },
  backContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  iconStyle: {
    color: theme.Colors.secondary
  },
  locationContainer: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5)
  }
}));

const AddressForm = (props) => {
  const params = useParams();
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigateTo = useNavigate();
  const [zoom, setZoom] = useState(17);
  const [isError, setIsError] = useState(false);
  const { state }: any = useLocation();
  const edit = useEdit(state?.formEdits);
  const { userDetails } = useUserInfo();
  const initialValues = {
    address_line1: '',
    address_line2: '',
    address_line3: '',
    pincode: '',
    location: '',
    state: '',
    city: '',
    latitude: '',
    longitude: '',
    map_url: ''
  };
  console.log('inside Address form', state);
  const addressEdit = useEdit(state?.pickupAddressNeeded? state?.pickupAddressEdit : state?.registeredAddressEdit);
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: 13.114626,
    lng: 80.162724
  });

  const handleSaveClick = () => {
    console.log('state?.pickupAddress======', state?.pickupAddress);
    if (state?.pickupAddressNeeded) {
      let addressData = {
        pickup_address: `${addressEdit.getValue(
          'address_line1'
        )},${addressEdit.getValue('address_line2')},${addressEdit.getValue(
          'address_line3'
        )}`
      };
      console.log('addressData inside pickup', addressData);

      let data = { ...state?.formEdits };
      navigateTo('/customer/create-account', {
        replace: true,
        state: {
          formEdits: data,
          customerId: state?.customerId,
          pickupAddress: addressData,
          pickupAddressEdit: addressEdit.edits,
          registeredAddress: state?.registeredAddress,
          registeredAddressEdit: state?.registeredAddressEdit
        }
      });
    } else {
      let addressData = {
        registered_address: `${addressEdit.getValue(
          'address_line1'
        )},${addressEdit.getValue('address_line2')},${addressEdit.getValue(
          'address_line3'
        )}`
      };
      let data = {
        ...state?.formEdits
      };
      console.log('addressData inside register', addressData);
      navigateTo('/customer/create-account', {
        replace: true,
        state: {
          formEdits: data,
          customerId: state?.customerId,
          registeredAddress: addressData,
          registeredAddressEdit: addressEdit.edits,
          pickupAddress: state?.pickupAddress,
          pickupAddressEdit: state?.pickupAddressEdit
        }
      });
    }
  };

  const getAddress = async ({
    latitude,
    longitude
  }: {
    latitude: number;
    longitude: number;
  }) => {
    await Geocode.fromLatLng(
      latitude.toString(),
      longitude.toString(),
      GOOGLE_API_KEY
    )
      .then((response: any) => {
        const address = response?.results[0].formatted_address;
        let street: any,
          street_number: any,
          address_line1: any,
          route: any,
          city: any,
          state: any,
          pincode: any,
          neighborhood: any,
          landmark: any;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case 'premise':
                address_line1 =
                  response.results[0].address_components[i].long_name;
                break;
              case 'neighborhood':
                neighborhood =
                  response.results[0].address_components[i].long_name;
                break;
              case 'route':
                route = response.results[0].address_components[i].long_name;
                break;
              case 'street_number':
                street_number =
                  response.results[0].address_components[i].long_name;
                break;
              case 'sublocality_level_1':
                street = response.results[0].address_components[i].long_name;
                break;
              case 'locality':
                city = response.results[0].address_components[i].long_name;
                break;
              case 'administrative_area_level_1':
                state = response.results[0].address_components[i].long_name;
                break;
              case 'postal_code':
                pincode = response.results[0].address_components[i].long_name;
                break;
              case 'landmark':
                landmark = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }

        addressEdit.update({
          /*  address_line1: address_line1,
          address_line2:
            street || street_number || route || neighborhood || landmark, */
          address_line3: `${capitalizeFirstLetter(city)},${state},${pincode}`,
          state: state,
          city: capitalizeFirstLetter(city),
          pincode: pincode,
          latitude: latitude,
          longitude: longitude,
          map_url: MAP_URL + latitude + ',' + longitude + ''
        });
      })
      .catch((error) => {
        console.log('error====', error);
        toast.error('Coordinates are invalid');
      });
  };

  useEffect(() => {
    console.log('inside UseEffect');
    navigator?.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: lng } }) => {
        const position = { lat, lng };
        console.log('position====', position);
        getAddress({ latitude: lat, longitude: lng });
        setCenter(position);
      },
      (posError) => toast.error(posError?.message),
      {
        enableHighAccuracy: true
      }
    );
  }, []);

  const onIdle = (m: google.maps.Map) => {
    console.log('m====', m);
    let data = m.getCenter()!.toJSON();
    console.log('data===', data);
    setZoom(m.getZoom()!);
    setCenter(data);
    getAddress({ latitude: data?.lat, longitude: data?.lng });
  };

  const render = (status: Status) => {
    return <h1 style={{ color: theme.Colors.primaryGreen }}>{status}</h1>;
  };

  const handleSearchTextChange = async (searchValue) => {
    console.log('inside Search Function');
    Geocode.fromAddress(searchValue).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        console.log(lat, lng);
      },
      (error) => {
        console.error(error);
      }
    );
  };
  return (
    <Grid>
      <Grid
        onClick={() =>
          navigateTo('/customer/create-account', {
            replace: true,
            state: {
              formEdits: { ...state?.formEdits.edits },
              customerId: state?.customerId,
              registeredAddress: state?.registeredAddress,
              registeredAddressEdit: state?.registeredAddressEdit,
              pickupAddress: state?.pickupAddress,
              pickupAddressEdit: state?.pickupAddressEdit
            }
          })
        }
        className={classes.backContainer}
      >
        <ArrowBackIcon fontSize={'small'} />
        <span className={classes.textStyles}>
          {t('LOGIN.confirmCompanyLocation')}
        </span>
      </Grid>
      <Grid className={classes.textFieldContainer}>
        <TextInputComponent
          inputHeight={66}
          placeholderText={t('LOGIN.search')}
          inputBorderRadius={0}
          textColor={theme.Colors.primary}
          onChange={(e) => handleSearchTextChange(e.target.value)}
        />
        <Grid container>
          <Grid item xs={12} xl={4} className={classes.map}>
            <Wrapper apiKey={GOOGLE_API_KEY} render={render}>
              <Map
                center={center}
                //onClick={onIdle}
                onIdle={onIdle}
                zoom={zoom}
                style={{
                  width: '100%',
                  height: '100%'
                }}
              >
                <Marker position={center} />
              </Map>
            </Wrapper>
            <Grid>
              <PlaceMaker className={classes.iconStyle} />
              {` ${addressEdit.getValue(
                'address_line2'
              )},${addressEdit.getValue('city')},${addressEdit.getValue(
                'pincode'
              )}`}
            </Grid>
          </Grid>
          <Grid item xs={12} xl={4} className={classes.textFieldContainer}>
            <TextInputComponent
              inputHeight={66}
              placeholderText={t('LOGIN.addressLine1')}
              value={addressEdit.getValue('address_line1')}
              onChange={(e) =>
                addressEdit.update({ address_line1: e.target.value })
              }
              inputBorderRadius={0}
              textColor={theme.Colors.primary}
              helperText={
                isError &&
                !addressEdit.allFilled('address_line1') &&
                'Please Enter your address'
              }
            />
            <TextInputComponent
              inputHeight={66}
              placeholderText={t('LOGIN.addressLine2')}
              value={addressEdit.getValue('address_line2')}
              onChange={(e) =>
                addressEdit.update({ address_line2: e.target.value })
              }
              inputBorderRadius={0}
              textColor={theme.Colors.primary}
              helperText={
                isError &&
                !addressEdit.allFilled('address_line2') &&
                'Please Enter your address'
              }
            />
            <TextInputComponent
              inputHeight={66}
              placeholderText={t('LOGIN.cityPincode')}
              value={addressEdit.getValue('address_line3')}
              onChange={(e) =>
                addressEdit.update({ address_line3: e.target.value })
              }
              inputBorderRadius={0}
              textColor={theme.Colors.primary}
              helperText={
                isError &&
                !addressEdit.allFilled('address_line3') &&
                'Please Enter your city and pincode'
              }
            />
            <ButtonComp
              buttonText={t('PROFILE.save')}
              backgroundColor={theme.Colors.secondary}
              btnBorderRadius={theme.MetricsSizes.tiny}
              onClickButton={handleSaveClick}
              style={{ margin: theme.spacing(2, 0) }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddressForm;
