import React, { useState } from 'react';
import { HTTP_STATUSES } from 'src/Config/constant';
import { API_SERVICES } from 'src/dustmanUI/Services';
import { CustomerGetAddressDataProp } from 'src/dustmanUI/Services/customerAddressService';

export type UserDetails = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  user_type_id: number;
  language_id: number;
  image_url: string;
  customer_id: number;
  mobile_number: string;
  full_address: string;
  pincode: string;
  location: string;
  player_id: string;
};

export type UserInfo = {
  userDetails: UserDetails;
  updateUserInfo: Awaited<(id: number) => Promise<void>>;
  userAddressDetails: CustomerGetAddressDataProp[];
  loggedInUser: string;
  updateLoggedInUser: React.Dispatch<React.SetStateAction<string>>;
  tabClicked: number;
  updateTabClicked: React.Dispatch<React.SetStateAction<number>>;
};

export const INITIAL_CUSTOMER_STATE: UserInfo = {
  userDetails: {
    id: 0,
    first_name: '',
    last_name: '',
    email: '',
    user_type_id: 0,
    language_id: 0,
    image_url: '',
    customer_id: 0,
    mobile_number: '',
    full_address: '',
    pincode: '',
    location: '',
    player_id: ''
  },
  updateUserInfo: () => undefined,
  userAddressDetails: [],
  loggedInUser: '',
  updateLoggedInUser: () => undefined,
  tabClicked: 1,
  updateTabClicked: () => undefined
};

export const UserInfoContext = React.createContext({
  ...INITIAL_CUSTOMER_STATE
});

type Props = {
  children: any;
};

export const UserInfoProvider = ({ children }: Props) => {
  const [userDetails, setUserDetails] = useState<UserDetails>(
    INITIAL_CUSTOMER_STATE.userDetails
  );
  const [userAddresses, setUserAddresses] = useState<
    CustomerGetAddressDataProp[]
  >(INITIAL_CUSTOMER_STATE.userAddressDetails);
  const [loginUserUrl, setLoginUserUrl] = useState<string>(
    INITIAL_CUSTOMER_STATE.loggedInUser
  );
  const [tabId, setTabId] = useState<number>(1);

  const updateUserDetails = async (id: number) => {
    if (id === 0) {
      setUserDetails(INITIAL_CUSTOMER_STATE.userDetails);
      setUserAddresses(INITIAL_CUSTOMER_STATE.userAddressDetails);
      return;
    }
    const response: any = await Promise.all([
      API_SERVICES.customerProfileService.getProfileByCustomerId(id),
      API_SERVICES.customerAddressService.getAddress(id)
    ]);
    if (response[0]?.status < HTTP_STATUSES.BAD_REQUEST) {
      if (response[0]?.data?.customer_profile) {
        setUserDetails((prev: UserDetails) => {
          return { ...prev, ...response[0]?.data?.customer_profile };
        });
      }
    }
    if (response[1]?.status < HTTP_STATUSES.BAD_REQUEST) {
      if (response[1]?.data?.address) {
        setUserAddresses(response[1]?.data?.address);
      }
    }
  };

  const contextValue = React.useMemo(() => {
    return {
      userDetails: userDetails,
      updateUserInfo: updateUserDetails,
      userAddressDetails: userAddresses,
      loggedInUser: loginUserUrl,
      updateLoggedInUser: setLoginUserUrl,
      tabClicked: tabId,
      updateTabClicked: setTabId
    };
  }, [userDetails, updateUserDetails, userAddresses, loginUserUrl, tabId]);

  return (
    <UserInfoContext.Provider value={contextValue}>
      {children}
    </UserInfoContext.Provider>
  );
};
