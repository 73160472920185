import React, { useEffect, useState } from 'react';
import { useTheme, Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  boldText: {
    color: theme.Colors.primary,
    fontWeight: theme.fontWeight.bold
  }
}));

export const TermsAndConditionComp = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Typography variant="subtitle2">
      {t('LOGIN.loginAndAccept')}
      <Link to="/terms-condition" target="_blank">
        {t('LOGIN.termsAndCondition')}
      </Link>
      <span className={classes.boldText}>& </span>
      <Link to="/privacy-policy" target="_blank">
        {t('LOGIN.privacyAndPolicy')}
      </Link>
    </Typography>
  );
};
