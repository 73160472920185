import React, { useCallback, useEffect, useState } from 'react';
import { Theme, useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import CustomerOrderModal from './CustomerOrderModal';
import { API_SERVICES } from 'src/dustmanUI/Services';
import { useTranslation } from 'react-i18next';
import useUserInfo from 'src/hooks/useUserInfo';
import {
  CONFIRM_MODAL,
  CUSTOMER_ORDER_STATUS,
  HTTP_STATUSES,
  ORIENTATION
} from 'src/Config/constant';
import {
  Loader,
  UHConfirmModal,
  UHOrderPreviewComp,
  UHTabComponent
} from 'src/components';
import { getDateFormat } from 'src/Utils';
import {
  Confirm,
  YetToConfirm,
  pending,
  Completed,
  confirmed,
  confirmedWhite,
  pendingWhite,
  completedWhite
} from 'src/Assets';
import toast from 'react-hot-toast';
import RescheduleOrderModal from 'src/components/RescheduleOrderModal.tsx';
import { useEdit } from 'src/hooks/useEdit';

const useStyles = makeStyles((theme: Theme) => ({
  outerContainer: {
    padding: theme.spacing(6.5, 3, 6.5, 3),
    background: theme.Colors.whitePure,
    margin: theme.spacing(1.75, 0, 1.75, 0)
  },
  tabContainer: {
    border: '0.5px solid',
    borderColor: theme.Colors.greyDark,
    paddingTop: theme.spacing(4)
  },
  tabContentStyle: {
    height: 513,
    overflowY: 'scroll'
  },
  tabContentContainer: {
    margin: theme.spacing(0, 2)
  },
  selectedTabStyle: {
    color: theme.Colors.whitePure,
    background: theme.Colors.secondary,
    fontWeight: theme.fontWeight.medium
  }
}));

function OrdersPage(orderData) {
  const classes = useStyles();
  const theme = useTheme();
  const initialValues = {
    customer_order_details: {
      pickup_time: orderData.pickup_time,
      slot: orderData.slot
    }
  };
  const edit = useEdit(initialValues);
  const { userDetails } = useUserInfo();
  const [selectedTab, setSelectedTab] = useState<number>(
    CUSTOMER_ORDER_STATUS.New
  );
  const [orderDetails, setOrderDetails] = useState([]);
  const [modalOpen, setModalOpen] = useState<any>({ open: false });
  const [scheduleOpen, setScheduleOpen] = useState<any>({ open: false });
  const [confirmModal, setConfirmModal] = useState<any>({
    open: false
  });

  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const updateSelectedDate = (dateString: string, slot: string) => {
    edit.update({
      customer_order_details: {
        ...edit.edits.customer_order_details,
        pickup_time: dateString,
        slot: slot
      }
    });
  };

  const onClickViewDetails = (orderData: any) => {
    setModalOpen({
      open: true,
      orderData: orderData
    });
  };

  const OrdersTabItems = [
    {
      tabIcon: () =>
        selectedTab == CUSTOMER_ORDER_STATUS.New ? (
          <img src={pendingWhite} />
        ) : (
          <img src={pending} />
        ),
      label: t('ORDER.pending'),
      value: CUSTOMER_ORDER_STATUS.New
    },
    {
      tabIcon: () =>
        selectedTab == CUSTOMER_ORDER_STATUS.Confirmed ? (
          <img src={confirmedWhite} />
        ) : (
          <img src={confirmed} />
        ),
      label: t('ORDER.confirmed'),
      value: CUSTOMER_ORDER_STATUS.Confirmed
    },
    {
      tabIcon: () =>
        selectedTab == CUSTOMER_ORDER_STATUS.Completed ? (
          <img src={completedWhite} />
        ) : (
          <img src={Completed} />
        ),
      label: t('ORDER.completed'),
      value: CUSTOMER_ORDER_STATUS.Completed
    }
  ];

  const fetchData = useCallback(async () => {
    try {
      let response: any;
      setOrderDetails([]);
      if (selectedTab === CUSTOMER_ORDER_STATUS.Confirmed) {
        response = await API_SERVICES.orderService.getConfirmedCustomerOrders(
          userDetails?.customer_id
        );
      } 
      else {
        response = await API_SERVICES.orderService.getCustomerOrderByStatus(
          userDetails?.customer_id,
          selectedTab
        );
      }
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (response?.data?.orders) {
          setOrderDetails(response.data.orders);
        }
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  }, [selectedTab]);

  const onClickRescheduleButton = async (orderData) => {
    setScheduleOpen({
      open: true,
      orderData: orderData
    });
  };

  const onRescheduleConfirmClick = async (orderData) => {
    let updateData = { ...edit.edits.customer_order_details };
    const response: any =
      await API_SERVICES.customerOrderService.replaceCustomerOrder(
        orderData?.id,
        {
          data: updateData,
          successMessage: `${t('TOAST.orderUpdatedSuccessfully')}`
        }
      );
    if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
      setScheduleOpen({ open: false });
      fetchData();
    }
  };

  const onClickCancelButton = (orderData: any) => {
    const onCancelClick = () => {
      setConfirmModal({ open: false });
    };

    const onConfirmClick = async () => {
      let updateData = {
        status_id: CUSTOMER_ORDER_STATUS.Cancelled,
        orders: [orderData?.order_id]
      };
      const response: any = await API_SERVICES.generalService.replaceOrder({
        data: updateData,
        successMessage: `${t('TOAST.orderCancelledSuccessfully')}`
      });
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        onCancelClick();
        setModalOpen({ open: false });
        fetchData();
      }
    };

    let props = {
      color: theme.Colors.redPrimary,
      description: t('ORDER.cancelCustomerOrder'),
      title: t('cancelOrder'),
      iconType: CONFIRM_MODAL.cancel
    };
    setConfirmModal({ open: true, onConfirmClick, onCancelClick, ...props });
  };

  const renderTabContent = () => {
    return (
      <Grid className={classes.tabContentContainer}>
        {orderDetails?.length ? (
          orderDetails.map((item, index) => {
            const { getTime, getDateString } = getDateFormat(item?.pickup_time);
            return (
              <Grid key={index}>
                <UHOrderPreviewComp
                  orderItems={item}
                  handleClickButtonOne={onClickViewDetails}
                  handleClickButtonTwo={onClickCancelButton}
                  handleClickButtonThree={onClickRescheduleButton}
                  buttonThreeText={t('ORDER.reschedule')}
                  orderStatusText={
                    ((item?.status_id === CUSTOMER_ORDER_STATUS.New ||
                      item?.status_id === CUSTOMER_ORDER_STATUS.Pending) &&
                      'Yet to Confirm') ||
                    (item?.status_id === CUSTOMER_ORDER_STATUS.Confirmed &&
                      `Scheduled on ${getDateString}, ${getTime}`) ||
                    (item?.status_id === CUSTOMER_ORDER_STATUS.Completed &&
                      `Completed on ${getDateString}, ${getTime}`)
                  }
                  statusIcon={
                    item?.status_id === CUSTOMER_ORDER_STATUS.New ||
                    item?.status_id === CUSTOMER_ORDER_STATUS.Pending
                      ? YetToConfirm
                      : Confirm
                  }
                  isButtonTwo={
                    item?.status_id === CUSTOMER_ORDER_STATUS.New ||
                    item?.status_id === CUSTOMER_ORDER_STATUS.Pending
                  }
                  isButtonThree={
                    item?.status_id === CUSTOMER_ORDER_STATUS.Confirmed
                  }
                />
              </Grid>
            );
          })
        ) : (
          <Typography variant="body2">{t('ORDER.noOrderfound')}</Typography>
        )}
      </Grid>
    );
  };

  const onTabChange = (tabValue: any) => {
    setSelectedTab(tabValue);
  };

  useEffect(() => {
    fetchData();
    
  }, [fetchData]);

  return (
    <>
      <Grid className={classes.outerContainer}>
        {loading ? (
          <Loader />
        ) : (
          <UHTabComponent
            currentTabVal={selectedTab}
            tabContent={OrdersTabItems}
            orientation={ORIENTATION.VERTICAL}
            tabClasses={{ selected: classes.selectedTabStyle }}
            tabIndicatorColor={theme.Colors.primary}
            isDivider={false}
            tabContainerClassName={classes.tabContainer}
            renderTabContent={renderTabContent}
            tabContentClassName={classes.tabContentStyle}
            onTabChange={onTabChange}
          />
        )}
      </Grid>
      {modalOpen.open && (
        <CustomerOrderModal
          onClose={() => setModalOpen({ open: false })}
          {...modalOpen}
          onCancelButtonClick={onClickCancelButton}
        />
      )}
      {scheduleOpen.open && (
        <RescheduleOrderModal
          onClose={() => setScheduleOpen({ open: false })}
          onCancelClick={() => setScheduleOpen({ open: false })}
          {...scheduleOpen}
          onRescheduleConfirmClick={onRescheduleConfirmClick}
          updateSelectedDate={updateSelectedDate}
          color={theme.Colors.secondary}
          activeButtonColor={theme.Colors.secondary}
          activeTileColor={theme.Colors.secondary}
        />
      )}
      {confirmModal.open && <UHConfirmModal {...confirmModal} />}
    </>
  );
}

export default OrdersPage;
