import { Grid, makeStyles, Theme, useTheme } from '@material-ui/core';
import { Call } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';

import ButtonComp from '../ButtonComp';
type CircleProps = {
  bgColor?: string;
};

const useStyles = makeStyles<Theme, CircleProps>((theme) => {
  return {
    buttonContainer1: {
      borderTopRightRadius: '20px',
      borderTopLeftRadius: '55px',
      borderBottomLeftRadius: '38px',
      boxShadow: '2px 4px 0px #888888'
    },
    textStyle: {
      paddingLeft: '42px',
      fontWeight: theme.fontWeight.bold,
      fontSize: theme.MetricsSizes.small_xxx
    },
    circleAlign: {
      position: 'absolute',
      zIndex: 1,
      filter: 'drop-shadow(0px 5px 1px #888888 )',
      background: (props) => props.bgColor || theme.Colors.secondary,
      height: 50,
      width: 50,
      borderRadius: '50%',
      padding: 2
    },

    circleAlign1: {
      border: '1px solid ',
      borderColor: theme.Colors.white,
      filter: 'none',
      width: 45,
      height: 45,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    iconAlign: {
      color: theme.Colors.white
    }
  };
});
type Props = {
  buttonTextColor?: string;
  //backgroundColor?: string;
  backgroundCircleColor?: string;
  buttonBackgroundColor?: string;
  handleButtonClick?: () => void;
};

const EnquiryComp = (props: Props) => {
  const { buttonTextColor, backgroundCircleColor, handleButtonClick } = props;
  const classes = useStyles({
    bgColor: backgroundCircleColor
  });
  const { t } = useTranslation();

  const theme = useTheme();

  return (
    <Grid container>
      <Grid item className={classes.circleAlign}>
        <Grid className={`${classes.circleAlign} ${classes.circleAlign1}`}>
          <Call className={classes.iconAlign} />
        </Grid>
      </Grid>
      <Grid item>
        <ButtonComp
          buttonText={t('LOGIN.callMeBack')}
          buttonTextColor={buttonTextColor || theme.Colors.secondary}
          backgroundColor={theme.Colors.white}
          variant="outlined"
          onClick={handleButtonClick}
          classes={{
            root: classes.buttonContainer1,
            label: classes.textStyle
          }}
        />
      </Grid>
    </Grid>
  );
};
export default EnquiryComp;
