import {
  Divider,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useTheme
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CurrencySymbol, locationIcon, weightIcon } from 'src/Assets';
import { ButtonComp, Heading, ImageTextComponent } from 'src/components';

const useStyles = makeStyles((theme: Theme) => {
  return {
    outerContainer: {
      border: '0.5px solid',
      borderColor: theme.Colors.greyDark,
      width: '100%',
      marginBottom: theme.spacing(3),
      padding: theme.spacing(6, 2, 6, 7),
      background: theme.Colors.whiteLightGrey
    },
    subText: {
      fontSize: theme.MetricsSizes.tiny_xxx,
      color: theme.Colors.darkGrey
    },
    categoryText: {
      color: theme.Colors.mediumGrey,
      marginLeft: theme.MetricsSizes.tiny
    },
    imageAlign: {
      paddingLeft: theme.spacing(1)
    },
    dashAlign: {
      height: '15px',
      '& hr': {
        width: '52px',
        border: 'none',
        borderTop: '1px dashed',
        borderColor: theme.Colors.black
      }
    },
    dividerStyle: {
      margin: theme.spacing(1, 0),
      padding: '2px',
      backgroundColor: theme.Colors.black
    },
    buttonOuteruterContainer: {
      position: 'absolute',
      left: '50%',
      paddingTop: theme.spacing(2.5)
    }
  };
});

type CheckoutProps = {
  orderItems: any[];
  handleCheckOutClick: (orderIds: number[]) => void;
  selectedIds: number[];
  customerOrderPrice: number;
};

const BillDetails = ({
  orderItems,
  customerOrderPrice
}: Omit<CheckoutProps, 'handleCheckOutClick' | 'selectedIds'>) => {
  const theme = useTheme();
  const classes = useStyles();
  customerOrderPrice *= orderItems?.length;
  let tax = 9.1 * orderItems?.length;
  const billDetails = [
    {
      content: 'Total item',
      value: orderItems?.length
    },
    {
      content: 'Item Total',
      value: customerOrderPrice
    },
    {
      content: 'Taxes and Charges',
      value: tax
    },
    {
      content: 'TO PAY',
      value: Math.floor(customerOrderPrice + tax)
    }
  ];
  return (
    <Grid container>
      <Grid item xs={12}>
        <Heading
          headingText={'Bill Details'}
          headerFontSize={theme.MetricsSizes.small_x}
          headingColor={theme.Colors.mediumGrey}
        />
      </Grid>
      <Grid container xs={5}>
        {billDetails.map((item, index) => {
          return (
            <Grid
              container
              xs={12}
              key={index}
              style={{ paddingBottom: '5px' }}
            >
              <Grid item>
                <Typography variant="subtitle2">{item?.content}</Typography>
              </Grid>
              <Grid item xs>
                <ImageTextComponent
                  style={{ justifyContent: 'flex-end' }}
                  icon={index !== 0 ? CurrencySymbol : null}
                  value={item?.value}
                />
              </Grid>
              {index === billDetails.length - 2 ? (
                <Grid item xs={12}>
                  <Divider classes={{ root: classes.dividerStyle }} />
                </Grid>
              ) : null}
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

const CheckOutScreen = ({
  orderItems,
  handleCheckOutClick,
  selectedIds,
  customerOrderPrice
}: CheckoutProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Grid className={classes.outerContainer}>
      <Grid item xs style={{ paddingBottom: theme.spacing(2) }}></Grid>
      {orderItems?.length
        ? orderItems.map((item, index) => {
            return (
              <Grid
                key={index}
                container
                alignItems="center"
                style={{ paddingBottom: theme.spacing(2) }}
              >
                <Grid item xs={12} style={{ paddingBottom: theme.spacing(2) }}>
                  <Typography className={classes.subText}>
                    {t('orders')}
                    {item.id}
                  </Typography>
                </Grid>
                <Grid item xs={5} container alignItems="flex-start">
                  <Grid item>
                    <Typography variant="subtitle2">
                      {t('category')}:
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="body2"
                      className={classes.categoryText}
                    >
                      {item?.order_items?.join(', ')}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs className={classes.imageAlign}>
                  <ImageTextComponent
                    icon={weightIcon}
                    value={`${item?.quantity_kg} Kg`}
                  />
                </Grid>
                <Grid item xs className={classes.imageAlign}>
                  <ImageTextComponent icon={locationIcon} value={item?.city} />
                </Grid>
                <Grid
                  item
                  xs
                  container
                  alignItems="center"
                  className={classes.dashAlign}
                >
                  <hr />
                </Grid>
                <Grid item xs className={classes.imageAlign}>
                  <Typography variant="subtitle2">{`RS. ${customerOrderPrice} /-`}</Typography>
                </Grid>
              </Grid>
            );
          })
        : null}
      {orderItems?.length ? (
        <Grid style={{ paddingTop: theme.spacing(4) }}>
          <BillDetails
            orderItems={orderItems}
            customerOrderPrice={customerOrderPrice}
          />
        </Grid>
      ) : null}
      <Grid className={classes.buttonOuteruterContainer}>
        <ButtonComp
          buttonText={t('ORDER.checkOut')}
          buttonFontSize={theme.MetricsSizes.small_xxx}
          backgroundColor={theme.Colors.orangePrimary}
          btnBorderRadius={105}
          height={'48px'}
          btnWidth={'217px'}
          onClickButton={() => handleCheckOutClick(selectedIds)}
        />
      </Grid>
    </Grid>
  );
};

export default CheckOutScreen;
