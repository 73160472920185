import { useState, useEffect } from 'react';
import { useTheme, Grid, Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import {
  ButtonComp,
  LoginHeaderComp,
  TextInputComponent
} from 'src/components';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useEdit } from 'src/hooks/useEdit';
import { capitalizeFirstLetter } from 'src/Utils';
import { TermsAndConditionComp } from 'src/dustmanUI/content/Login/TermsAndConditionComp';
import { PlaceMaker } from 'src/Assets';
import { MAP_URL, GOOGLE_API_KEY } from 'src/Config/constant';
import toast from 'react-hot-toast';
import Geocode from 'react-geocode';
Geocode.setApiKey(GOOGLE_API_KEY);

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(5)
  },
  getGps: {
    fontSize: theme.MetricsSizes.small_x,
    marginRight: theme.MetricsSizes.xx_large
  },
  getLocation: {
    fontSize: theme.MetricsSizes.small_xxx,
    fontWeight: theme.fontWeight.bold
  }
}));
type Location = {
  lat: number;
  lng: number;
};
const ChooseLocation = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigateTo = useNavigate();
  const [isError, setIsError] = useState(false);
  const { state }: any = useLocation();
  const [location, setLocation] = useState<Location>({
    lat: 13.113974765565846,
    lng: 80.15234154647385
  });
  const edit = useEdit(state?.formEdits);
  const addressError = isError && !edit.allFilled('full_address');

  const handleContinueClick = () => {
    let data = { ...state?.formEdits, ...edit.edits };
    if (!edit.allFilled('full_address')) {
      setIsError(true);
      return;
    }
    if (state?.vendorId) {
      navigateTo('/vendor/choose-vehicle-type', {
        state: { formEdits: data, vendorId: state?.vendorId },
        replace: true
      });
    } else if (state?.customerId) {
      navigateTo('/customer/choose-user-type', {
        state: { formEdits: data, customerId: state?.customerId },
        replace: true
      });
    }
  };
  const getAddress = async () => {
    await Geocode.fromLatLng(
      location.lat.toString(),
      location.lng.toString(),
      GOOGLE_API_KEY
    ).then(
      (response: any) => {
        const address = response.results[0].formatted_address;
        let street: any,
          street_number: any,
          address_line1: any,
          route: any,
          city: any,
          state: any,
          pincode: any,
          neighborhood: any,
          landmark: any;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case 'premise':
                address_line1 =
                  response.results[0].address_components[i].long_name;
                break;
              case 'neighborhood':
                neighborhood =
                  response.results[0].address_components[i].long_name;
                break;
              case 'route':
                route = response.results[0].address_components[i].long_name;
                break;
              case 'street_number':
                street_number =
                  response.results[0].address_components[i].long_name;
                break;
              case 'sublocality_level_1':
                street = response.results[0].address_components[i].long_name;
                break;
              case 'locality':
                city = response.results[0].address_components[i].long_name;
                break;
              case 'administrative_area_level_1':
                state = response.results[0].address_components[i].long_name;
                break;
              case 'postal_code':
                pincode = response.results[0].address_components[i].long_name;
                break;
              case 'landmark':
                landmark = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }

        edit.update({
          full_address: `${street},${city},${state}`,
          map_url: MAP_URL + location.lat + ',' + location.lng + ''
        });
      },
      (error: any) => {
        toast.error('Coordinates are invalid');
      }
    );
  };

  useEffect(() => {
    navigator?.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: lng } }) => {
        const position = { lat, lng };
        setLocation(position);
        const getMapUrl: string = `${MAP_URL}${lat},${lng}`;
        edit.update({ map_url: getMapUrl });
      },
      (posError) => toast.error(posError?.message),
      {
        enableHighAccuracy: true
      }
    );
  }, []);

  const getCurrentLocation = () => {
    return (
      <Grid container justifyContent="flex-start">
        <Typography className={classes.getLocation}>
          {t('LOGIN.getCurrentLocation')}
        </Typography>
        <Typography className={classes.getGps}>
          {t('LOGIN.usingGps')}
        </Typography>
      </Grid>
    );
  };

  return (
    <Grid>
      <LoginHeaderComp
        title={t('LOGIN.signUp')}
        subText={t('LOGIN.logintoYourAccount')}
        color={
          state.vendorId ? theme.Colors.orangePrimary : theme.Colors.secondary
        }
      />
      <Grid className={classes.container}>
        <TextInputComponent
          inputHeight={66}
          placeholderText={t('LOGIN.enterLocation')}
          value={edit.getValue('full_address')}
          onChange={(e) =>
            edit.update({ full_address: capitalizeFirstLetter(e.target.value) })
          }
          inputBorderRadius={0}
          textColor={theme.Colors.primary}
          isError={addressError}
          helperText={addressError && 'Enter location, area, street name'}
        />
        <ButtonComp
          buttonText={getCurrentLocation()}
          backgroundColor={theme.Colors.white}
          btnBorderRadius={theme.MetricsSizes.tiny}
          onClickButton={getAddress}
          style={{
            margin: theme.spacing(2, 0)
          }}
          startIcon={<PlaceMaker style={{ margin: theme.spacing(1, 0) }} />}
          variant="outlined"
          buttonTextColor={theme.Colors.inputText}
          height={theme.MetricsSizes.xx_large}
        />

        <ButtonComp
          buttonText={t('LOGIN.continue')}
          backgroundColor={
            state.vendorId ? theme.Colors.orangePrimary : theme.Colors.secondary
          }
          btnBorderRadius={theme.MetricsSizes.tiny}
          onClickButton={handleContinueClick}
          style={{ margin: theme.spacing(2, 0) }}
        />
        <TermsAndConditionComp />
      </Grid>
    </Grid>
  );
};

export default ChooseLocation;
