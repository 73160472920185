import React, { useEffect } from 'react';
import {
  gallery,
  g1,
  g2,
  g3,
  g4,
  g5,
  g6,
  g7,
  g8,
  g9,
  g10,
  g11,
  g12
} from 'src/Assets';
import './website.css';
import { useNavigate } from 'react-router';
import { t } from 'i18next';
function Gallery() {
  const navigateTo = useNavigate();
  const navigationToHome = () => {
    navigateTo('/', { replace: true });
  };
  useEffect(() => {
    const indicator: any = document.querySelector('.indicator').children;
    const main: any = document.querySelector('.pictures').children;
    
    for (var i = 0; i < indicator.length; i++) {
      indicator[i].addEventListener('click', function () {
        for (let x = 0; x < indicator.length; x++) {
          indicator[x].classList.remove('active');
        }

        this.classList.add('active');
        const displayItems = this.getAttribute('data-filter');

        for (let z = 0; z < main.length; z++) {
          main[z].style.transform = 'scale(0)';
          main[z].style.display = 'none';
          
          if (main[z].getAttribute('data-category') == displayItems) {
            main[z].style.transform = 'scale(1)';
            main[z].style.display = 'block';
          }
        }
      });
    }
  }, []);

  return (
    <div>
      <img className="gallery-pic" src={gallery} alt="" />
      <section>
        <div className="gallery-page">
          <div className="side-head">
            <a onClick={navigationToHome}>{t('WEBGALLERY.home')}</a> |
            <a className="current-view">{t('WEBGALLERY.Gallery')}</a>
          </div>
          <div className="gallery-container">
            <h1 className="gallery-title">{t('WEBGALLERY.Gallery')}</h1>
            <div className="select-gallery">
              <ul className="indicator">
                <li data-filter="all" className="active">
                  {t('WEBGALLERY.ShowAll')}
                </li>
                <li data-filter="Facilities"> {t('WEBGALLERY.Facilities')}</li>
                <li data-filter="Woodern Recycling Unit">
                  {t('WEBGALLERY.WoodernRecyclingUnit')}
                </li>
                <li data-filter="Plastic Recycling Unit">
                  {t('WEBGALLERY.PlasticRecyclingUnit')}
                </li>
                <li data-filter="Cotton Box"> {t('WEBGALLERY.CottonBox')}</li>
                <li data-filter="Zero Waste Scrap">
                  {t('WEBGALLERY.ZeroWasteScrap')}
                </li>
              </ul>
            </div>
            <div className="pictures">
              <div className="gallery-pictures" data-category="Facilities">
                <img src={g1} alt="" />
                <h3 className="pic-title">
                  {t('WEBGALLERY.SpecialEquipment')}
                </h3>
              </div>
              <div className="gallery-pictures" data-category="Facilities">
                <img src={g2} alt="" />
                <h3 className="pic-title">{t('WEBGALLERY.Forklifts')}</h3>
              </div>
              <div className="gallery-pictures" data-category="Facilities">
                <img src={g3} alt="" />
                <h3 className="pic-title">{t('WEBGALLERY.Vehicles')}</h3>
              </div>
              <div
                className="gallery-pictures"
                data-category="Woodern Recycling Unit"
              >
                <img src={g4} alt="" />
                <h3 className="pic-title">{t('WEBGALLERY.MakingDoor')}</h3>
              </div>
              <div
                className="gallery-pictures"
                data-category="Woodern Recycling Unit"
              >
                <img src={g5} alt="" />
                <h3 className="pic-title">{t('WEBGALLERY.MakingFurniture')}</h3>
              </div>
              <div
                className="gallery-pictures"
                data-category="Woodern Recycling Unit"
              >
                <img src={g6} alt="" />
                <h3 className="pic-title">
                  {t('WEBGALLERY.MakingWoodReaper')}
                </h3>
              </div>
              <div className="gallery-pictures" data-category="Facilities">
                <img src={g7} alt="" />
                <h3 className="pic-title">
                  {t('WEBGALLERY.ProcessingMachine')}
                </h3>
              </div>
              <div
                className="gallery-pictures"
                data-category="Plastic Recycling Unit"
              >
                <img src={g8} alt="" />
                <h3 className="pic-title">{t('WEBGALLERY.LDPEGranules')}</h3>
              </div>
              <div
                className="gallery-pictures"
                data-category="Plastic Recycling Unit"
              >
                <img src={g9} alt="" />
                <h3 className="pic-title">{t('WEBGALLERY.NylonGranules')}</h3>
              </div>
              <div className="gallery-pictures" data-category="Cotton Box">
                <img src={g10} alt="" />
                <h3 className="pic-title">{t('WEBGALLERY.PaperRolls')}</h3>
              </div>
              <div className="gallery-pictures" data-category="Cotton Box">
                <img src={g11} alt="" />
                <h3 className="pic-title">
                  {t('WEBGALLERY.LooseCartonBoxes')}
                </h3>
              </div>
              <div
                className="gallery-pictures"
                data-category="Zero Waste Scrap"
              >
                <img src={g12} alt="" />
                <h3 className="pic-title">
                  {t('WEBGALLERY.IndustrialSolidWaste')}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Gallery;
