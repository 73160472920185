import React from 'react';
import { Link, useTheme } from '@material-ui/core';

type Props = {
  title?: string;
  color?: string;
  disabled?: boolean;
  style?: object;
  image?: any;
  underline?: 'none' | 'always' | 'hover';
  url?: string | URL;
  // onClick?: (title: string) => void;
};

const LinkComp = (props: Props) => {
  const {
    title,
    color,
    disabled,
    style,
    url,
    underline = 'always',
    image
  } = props;
  const theme = useTheme();

  const handleClick = () => {
    if (disabled) {
      return;
    }
    window.open(url);
  };

  return (
    <Link
      style={{
        opacity: disabled ? 0.3 : 1,
        color: color || '#4285F4',
        cursor: disabled ? 'default' : 'pointer',
        fontSize: image
          ? theme.MetricsSizes.tiny_xx
          : theme.MetricsSizes.small_xxx,
        fontWeight: theme.fontWeight.regular,
        ...style
      }}
      onClick={handleClick}
      underline={underline}
    >
      {image ? <img src={image} width={'30.38px'} height={'24.75px'} /> : ''}
      {title}
    </Link>
  );
};
export default LinkComp;
