import { useContext, useEffect, useState } from 'react';
import { useTheme, Theme, makeStyles, IconButton } from '@material-ui/core';
import { LoginDrawerContext } from '../../../contexts/LoginDrawerContext';
import { Pathname, useMatch, useNavigate } from 'react-router';
import { Logo, DropDown, UserAvatarIcon } from 'src/Assets';
import useVendorInfo from 'src/hooks/useVendorInfo';
import useUserInfo from 'src/hooks/useUserInfo';
import { ListItemCell } from 'src/components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import toast from 'react-hot-toast';
import { INITIAL_CUSTOMER_STATE } from 'src/contexts/UserContext';
import MenuActionComp from 'src/components/MenuActionComp';
import { ExitToApp } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, Badge, Popover, Box } from '@material-ui/core';
import BellIcon from '../../../Assets/Images/BellIcon.svg';
import { ButtonComp, Heading } from 'src/components';
import { Close, FiberManualRecord } from '@material-ui/icons';
import { API_SERVICES } from 'src/dustmanUI/Services';
import { HTTP_STATUSES } from 'src/Config/constant';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: theme.MetricsSizes.large_x,
    paddingInline: theme.spacing(1.5),
    backgroundColor: theme.Colors.whitePrimary
  },
  mainContainer: {
    padding: theme.spacing(2, 0, 4, 0),
    zIndex: 6,
    backgroundColor: theme.Colors.white,
    width: '100%',
    position: 'sticky',
    top: 0
  },
  headerIcon: {
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    },
    display: 'inline-block'
  },
  leftGrid: {
    display: 'flex',
    alignItems: 'center'
  },
  headerText: {
    fontSize: theme.MetricsSizes.medium_x,
    color: theme.Colors.blueDark,
    fontWeight: theme.fontWeight.bold
  },
  subHeader: {
    fontSize: theme.MetricsSizes.small_xxx,
    color: theme.Colors.blueLight
  },
  image: {
    margin: theme.spacing(0, 1, 0, 1)
  },
  avatarStyle: {
    height: 32,
    width: 32
  },
  select: {
    padding: theme.spacing(0, 1.5),
    fontSize: theme.MetricsSizes.small_xxx,
    color: theme.Colors.black
  },
  badgeStyle: {
    '& .MuiBadge-badge': {
      background: theme.Colors.redPrimary
    }
  },
  popover: {
    padding: theme.spacing(0, 2, 2, 2),
    width: 400,
    height: 300
  },
  titleStyle: {
    fontSize: theme.MetricsSizes.small_xx,
    fontWeight: theme.fontWeight.bold,
    color: theme.Colors.blueDark,
    marginLeft: theme.MetricsSizes.tiny_xxx
  },
  contentStyle: {
    display: 'flex',
    alignItems: 'initial',
    marginBottom: theme.spacing(1)
  },
  notificationHeader: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    position: 'sticky',
    top: 0,
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    background: theme.Colors.white
  }
}));

function NavBar() {
  const theme = useTheme();
  const classes = useStyles();
  const navigateTo = useNavigate();
  const styles = useStyles();

  const { toggleLoginDrawer, isLoginDrawerOpen } =
    useContext(LoginDrawerContext);
  const { vendorDetails, updateVendorInfo } = useVendorInfo();
  const {
    userDetails,
    loggedInUser,
    updateLoggedInUser,
    updateUserInfo,
    updateTabClicked
  } = useUserInfo();
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const { t, i18n } = useTranslation();
  const [notificationData, setNotificationData] = useState([]);
  const [notifyAnchorEl, setNotifyAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setNotifyAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setNotifyAnchorEl(null);
  };
  const handleCustomerButtonClick = () => {
    toggleLoginDrawer();
    document.getElementById('login-list').classList.remove('show');
    navigateTo('/customer', { replace: true });
  };
  const handleVendorButtonClick = () => {
    toggleLoginDrawer();
    document.getElementById('login-list').classList.remove('show');
    navigateTo('/vendor', { replace: true });
  };

  function showLoginOptions() {
    document.getElementById('login-list').classList.toggle('show');
  }

  const handleClickNavItem = (url: Pathname) => {
    navigateTo(url);
    document.getElementById('login-list').classList.remove('show');
  };

  const navigationToHome = () => {
    navigateTo('/', { replace: true });
  };
  const homePage = useMatch('home');
  const aboutUs = useMatch('about-us');
  const services = useMatch('services');
  const gallery = useMatch('gallery');
  const contactUs = useMatch('contact-us');
  //const bookYourPickup = useMatch('/customer-home/customer-info');
  const customerHome = useMatch('/customer-home/customer-info');
  //const cutomerHomeMain = useMatch('/customer-home/home');
  const vendorHome = useMatch('/vendor-home/vendor-info');

  useEffect(() => {
    if (customerHome && userDetails?.customer_id !== 0) {
      updateLoggedInUser(customerHome?.pathname);
    }
    if (vendorHome && vendorDetails?.vendor_id !== 0) {
      updateLoggedInUser(vendorHome?.pathname);
    }
  }, [
    userDetails?.customer_id,
    customerHome,
    vendorHome,
    vendorDetails?.vendor_id
  ]);

  const onClickCustomerProfile = () => {
    console.log('Cuhome', customerHome, 'loggin', loggedInUser);
    updateTabClicked(3);
    if (!customerHome && loggedInUser !== '') {
      console.log('if');

      navigateTo(loggedInUser, { replace: true });
    } else {
      navigateTo('/customer-home', {
        replace: true
      });
    }
  };

  const onClickVendorProfile = () => {
    if (!vendorHome && loggedInUser !== '') {
      navigateTo(loggedInUser, { replace: true });
    }
  };

  const handleLogout = () => {
    try {
      if (
        loggedInUser === '/customer-home/customer-info' &&
        userDetails?.customer_id
      ) {
        localStorage.removeItem('customerId');
        updateLoggedInUser(INITIAL_CUSTOMER_STATE.loggedInUser);
        updateUserInfo(0);
        navigateTo('/', { replace: true });
        toast.success(t('TOAST.userLoggedSuccessfully'));
      } else if (
        loggedInUser === '/vendor-home/vendor-info' &&
        vendorDetails?.vendor_id
      ) {
        localStorage.removeItem('vendorId');
        updateLoggedInUser(INITIAL_CUSTOMER_STATE.loggedInUser);
        updateVendorInfo(0);
        navigateTo('/', { replace: true });
        toast.success(t('TOAST.vendorLoggedOutSuccessfully'));
      }
    } catch (err) {
      toast.error(err);
    } finally {
      setAnchorEl(null);
    }
  };

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const onClickClearNotification = async () => {
    if (!notificationData.length) {
      return;
    }
    let userId = userDetails.user_type_id == 1 ? 2 : 1;
    let id =
      userDetails.user_type_id === 1
        ? localStorage.getItem('customerId')
        : localStorage.getItem('vendorId') || '1';
    await API_SERVICES.pushNotificationService.clearAllNotification(
      userId,
      parseInt(id)
      // userDetails.id
    );
    setNotifyAnchorEl(false);
    window.location.reload();
  };
  const getActionItems = () => {
    return [
      { renderIcon: () => <ExitToApp />, text: 'Logout', onClick: handleLogout }
    ];
  };

  // useEffect(() => {
  //   fetchData();
  // }, [userDetails.id]);
  const fetchData = async () => {
    if (userDetails.customer_id !== 0 || vendorDetails?.vendor_id !== 0) {
      let userId = userDetails.user_type_id == 1 ? 1 : 2;
      let id =
        userDetails.user_type_id == 1
          ? userDetails.customer_id
          : vendorDetails?.vendor_id;
      const response: any =
        await API_SERVICES.pushNotificationService.getAllPushNotification(
          userId,
          id
        );
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (response?.data?.notifications?.length) {
          const data = response?.data?.notifications.map(
            (item: any) => item.notification
          );
          setNotificationData([...new Set(data)]);
        } else {
          setNotificationData([]);
        }
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <nav className="navbar">
        <div>
          <img
            onClick={navigationToHome}
            className="logo"
            src={Logo}
            alt="logo"
          />
        </div>
        <div className="navigation-bar">
          <ul className="nav-list" id="navi-list">
            <li className="list-item">
              <a
                className={homePage && 'current-page'}
                onClick={() => handleClickNavItem('/home')}
              >
                {t('WEBHOME.home')}
              </a>
            </li>
            <li className="list-item">
              <a
                className={aboutUs && 'current-page'}
                onClick={() => handleClickNavItem('/about-us')}
              >
                {t('WEBHOME.aboutUs')}
              </a>
            </li>
            <li className="list-item">
              <a
                className={services && 'current-page'}
                onClick={() => handleClickNavItem('/services')}
              >
                {t('WEBHOME.services')}
              </a>
            </li>
            <li className="list-item">
              <a
                className={gallery && 'current-page'}
                onClick={() => handleClickNavItem('/gallery')}
              >
                {t('WEBHOME.gallery')}
              </a>
            </li>
            <li className="list-item">
              <a
                className={contactUs && 'current-page'}
                onClick={() => handleClickNavItem('/contact-us')}
              >
                {t('WEBHOME.contactUs')}
              </a>
            </li>
          </ul>

          <div className="empty"></div>
          {customerHome || vendorHome || loggedInUser !== '' ? (
            <>
              <div style={{ cursor: 'pointer' }}>
                {((customerHome ||
                  loggedInUser === '/customer-home/customer-info') && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '10px'
                    }}
                  >
                    <ButtonComp
                      backgroundColor={theme.Colors.primary}
                      height="40px"
                      buttonText={t('Book Your Pickup')}
                      buttonFontSize={theme.MetricsSizes.small_xx}
                      buttonFontWeight={theme.fontWeight.medium}
                      btnWidth="180px"
                      btnBorderRadius={36}
                      onClickButton={() => {
                        updateTabClicked(1);
                        navigateTo('/customer-home', {
                          replace: true
                        });
                      }}
                    />
                    <div
                      onClick={onClickCustomerProfile}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '10px'
                      }}
                    >
                      <ListItemCell
                        avatarImg={userDetails?.image_url || UserAvatarIcon}
                        title={`${userDetails?.first_name} ${userDetails?.last_name}`}
                        titleStyle={{ fontSize: theme.MetricsSizes.small_xxx }}
                      />
                    </div>
                  </div>
                )) ||
                  ((vendorHome ||
                    loggedInUser === '/vendor-home/vendor-info') && (
                    <div
                      onClick={onClickVendorProfile}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '10px'
                      }}
                    >
                      <ListItemCell
                        avatarImg={vendorDetails?.image_url || UserAvatarIcon}
                        title={vendorDetails?.contact_name}
                        titleStyle={{ fontSize: theme.MetricsSizes.small_xxx }}
                      />
                    </div>
                  ))}
              </div>
              <IconButton onClick={handleClick} style={{ padding: 1 }}>
                <ExpandMoreIcon />
              </IconButton>
              <MenuActionComp
                open={Boolean(anchorEl)}
                rowActions={getActionItems()}
                handleClose={handleCloseMenu}
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              />
              <IconButton className={styles.image} onClick={handlePopoverOpen}>
                <Badge
                  variant="dot"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  className={notificationData?.length && styles.badgeStyle}
                >
                  <img src={BellIcon} />
                </Badge>
              </IconButton>

              <Popover
                open={Boolean(notifyAnchorEl)}
                anchorEl={notifyAnchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                onClose={handlePopoverClose}
                classes={{ paper: styles.popover }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} className={styles.notificationHeader}>
                    <Heading
                      headingText={t('notifications')}
                      headingColor={theme.Colors.accentGrey}
                    />
                    <ButtonComp
                      buttonText={'Clear All'}
                      buttonFontSize={theme.MetricsSizes.small_xx}
                      buttonFontWeight={theme.fontWeight.regular}
                      backgroundColor={'transparent'}
                      buttonTextColor={theme.Colors.black}
                      startIcon={<Close fontSize="small" color="primary" />}
                      btnWidth={120}
                      onClick={onClickClearNotification}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {notificationData.length ? (
                      notificationData.map((item, index) => {
                        return (
                          <Box className={styles.contentStyle} key={index}>
                            <FiberManualRecord
                              fontSize="small"
                              style={{ color: theme.Colors.blueLight }}
                            />
                            <Typography className={styles.titleStyle}>
                              {item}
                            </Typography>
                          </Box>
                        );
                      })
                    ) : (
                      <Typography className={styles.titleStyle}>
                        No New Notifications!
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Popover>
            </>
          ) : (
            <div className="login-item">
              {/* <button
                className="login-btn"
                id="login-btn"
                onClick={showLoginOptions}
              >
                {t('WEBHOME.logIn')}
                <img src={DropDown} />
              </button>
              <div className="login-list" id="login-list">
                <a onClick={handleCustomerButtonClick}>Customer</a>
                <a onClick={handleVendorButtonClick}>Vendor</a>
              </div> */}
            </div>
          )}
        </div>
      </nav>
    </>
  );
}

export default NavBar;
