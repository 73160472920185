import React from 'react';
import { icon1, icon2, icon3, icon4, icon5, icon6, otherPic } from 'src/Assets';
import './website.css';
import { useNavigate } from 'react-router';
import { t } from 'i18next';
function Services() {
  const navigateTo = useNavigate();
  const navigationToHome = () => {
    navigateTo('/', { replace: true });
  };
  return (
    <>
      <img className="other-pic" src={otherPic} alt="" />
      <section>
        <div className="other-page1">
          <div className="side-head">
            <a onClick={navigationToHome}>{t('WEBSERVICE.home')}</a> |{' '}
            <a className="current-view">{t('WEBSERVICE.service')}</a>
          </div>

          <h1>{t('WEBSERVICE.ourServices')}</h1>
          <p>{t('WEBSERVICE.ourServicesPara')}</p>
          <br></br>
          <div className="container">
            <div className="box">
              <div className="square">
                <img className="icon-img" src={icon1} alt="" />
              </div>

              <h2>
                {t('WEBSERVICE.generalwaste')} <br></br>
                {t('WEBSERVICE.recycling')}
              </h2>
              <p>{t('WEBSERVICE.hazardous')}</p>
            </div>

            <div className="box">
              <div className="square">
                <img className="icon-img" src={icon2} alt="" />
              </div>

              <h2>
                {t('WEBSERVICE.wooden')} <br></br> {t('WEBSERVICE.recycling')}
              </h2>
              <p>{t('WEBSERVICE.weAreRecycling')}</p>
            </div>

            <div className="box">
              <div className="square">
                <img className="icon-img" src={icon3} alt="" />
              </div>
              <h2>
                {t('WEBSERVICE.plastic')} <br></br>
                {t('WEBSERVICE.recycling')}
              </h2>
              <p>{t('WEBSERVICE.plasticRecycling')}</p>
            </div>

            <div className="box">
              <div className="square">
                <img className="icon-img" src={icon4} alt="" />
              </div>
              <h2>
                {t('WEBSERVICE.metal')} <br></br>
                {t('WEBSERVICE.recycling')}
              </h2>
              <p>{t('WEBSERVICE.metalPara')}</p>
            </div>

            <div className="box">
              <div className="square">
                <img className="icon-img" src={icon5} alt="" />
              </div>
              <h2>
                {t('WEBSERVICE.aluminiumWaste')} <br></br>
                {t('WEBSERVICE.recycling')}
              </h2>
              <p>{t('WEBSERVICE.aluminiumWastePara')}</p>
            </div>

            <div className="box">
              <div className="square">
                <img className="icon-img" src={icon6} alt="" />
              </div>
              <h2>
                {t('WEBSERVICE.eWasteManagement')} <br></br>
                {t('WEBSERVICE.recycling')}
              </h2>
              <p>{t('WEBSERVICE.eWasteManagementPara')}</p>
            </div>
          </div>
        </div>
        <div className="other-page2">
          <p className="title">
            {t('WEBSERVICE.monthlyMaterial')} <br></br>
            {t('WEBSERVICE.capacity')}
          </p>
          <div className="table-box">
            <table>
              <tr>
                <td>{t('WEBSERVICE.cartonScraps')}</td>
                <td>200 MT</td>
              </tr>
              <tr>
                <td>{t('WEBSERVICE.woodenScraps')}</td>
                <td>275 MT</td>
              </tr>
              <tr>
                <td>{t('WEBSERVICE.generalWaste')}</td>
                <td>40 MT</td>
              </tr>
              <tr>
                <td>{t('WEBSERVICE.plasticScraps')}</td>
                <td>50 MT</td>
              </tr>
              <tr>
                <td>{t('WEBSERVICE.msScraps')}</td>
                <td>250 MT</td>
              </tr>
              <tr>
                <td>{t('WEBSERVICE.aluminiumScraps')}</td>
                <td>10 MT</td>
              </tr>
              <tr>
                <td>{t('WEBSERVICE.rubbishScraps')}</td>
                <td>150 MT</td>
              </tr>
              <tr>
                <td>{t('WEBSERVICE.glassScraps')}</td>
                <td>150 MT</td>
              </tr>
              <tr>
                <td>{t('WEBSERVICE.rubberScraps')}</td>
                <td>10 MT</td>
              </tr>
              <tr>
                <td>{t('WEBSERVICE.copperScraps')}</td>
                <td>0.5 MT</td>
              </tr>
              <tr>
                <td>{t('WEBSERVICE.otherMunicipality')}</td>
                <td>100 MT</td>
              </tr>
            </table>
          </div>
        </div>
      </section>
    </>
  );
}
export default Services;
