import { Config } from 'src/Config';
import { apiOptions } from 'src/Utils/apiOptions';
import { apiRequest } from 'src/Utils/apiRequest';

type VendorRescheduleProp = {
  data:{
    pickup_time: string;
    slot: string;
  };
  successMessage?: string;
  failureMessage?: string;
}

export const vendorMyOrderService = {
  getVendorOrderByStatus: async (vendorId: number, statusId: number) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/getDustmanOrdersByStatusType/vendor/${vendorId}/status/${statusId}`,
      method: 'get'
    });
    return apiRequest(options);
    //return OrderData.OrderServiceGet(statusId);
  },
  
  replaceVendorOrder:async(orderId: number,
    { data, successMessage, failureMessage }: VendorRescheduleProp)=>{
     const options = await apiOptions({
        url: `${Config.BASE_URL}/api/rescheduleTheVendorDropOrder/order/${orderId}`,
        method: 'patch',
        data: data
     });
     const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },

  replaceVendorPickupOrder:async(orderId: number,
    { data, successMessage, failureMessage }: VendorRescheduleProp)=>{
     const options = await apiOptions({
        url: `${Config.BASE_URL}/api/rescheduleTheVendorPickUpOrder/order/${orderId}`,
        method: 'patch',
        data: data
     });
     const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  }
};
