import React, { useRef, useState, useCallback, useLayoutEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Theme, Typography, useTheme } from '@material-ui/core';
import {
  AvatarCustomer,
  pending,
  Completed,
  confirmed,
  confirmedWhite,
  pendingWhite,
  completedWhite
} from 'src/Assets/Images';
import { useTranslation } from 'react-i18next';
import {
  CONFIRM_MODAL,
  DIALOG_TYPE,
  HTTP_STATUSES,
  LANGUAGE_ID,
  ORIENTATION,
  PROFILE_TAB_VALUES,
  USER_TYPE_ID
} from 'src/Config/constant';
import {
  ListItemCell,
  UHAccordionComp,
  UHAddressModalComp,
  UHConfirmModal,
  UHIconTextComp,
  UHSelectComp,
  UHTabComponent
} from 'src/components';
import ProfileContent from './profileContent';
import useUserInfo from 'src/hooks/useUserInfo';
import { Translate, UserSwitch, CustomerAboutInfo } from 'src/Assets';
import {
  ChevronRight,
  ExpandLess,
  ExpandMore,
  HelpOutlineOutlined,
  InfoOutlined,
  ExitToApp
} from '@material-ui/icons';
import { UHIconTextProps } from 'src/components/UHIconTextComp';
import { useNavigate } from 'react-router';
import { API_SERVICES } from 'src/dustmanUI/Services';
import toast from 'react-hot-toast';
import { AddressData } from 'src/dustmanUI/Services/customerAddressService';
import { INITIAL_CUSTOMER_STATE } from 'src/contexts/UserContext';

const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    padding: theme.spacing(6.5, 3, 6.5, 3),
    background: theme.Colors.whitePure,
    margin: theme.spacing(1.75, 0, 1.75, 0)
  },
  tabContainer: {
    border: '0.5px solid',
    borderColor: theme.Colors.greyDark,
    paddingTop: theme.spacing(4)
  },
  tabContentEachContainer: {
    border: '0.5px solid',
    borderColor: theme.Colors.greyDark,
    padding: theme.spacing(0, 2.5),
    backgroundColor: theme.Colors.whiteLightGrey
  },
  selectedTabStyle: {
    color: theme.Colors.whitePure,
    background: theme.Colors.secondary,
    fontWeight: theme.fontWeight.medium
  },
  tabContentStyle: {
    maxHeight: 650,
    overflowY: 'scroll'
  },
  tabContentContainer: {
    margin: theme.spacing(0, 2)
  },
  accordionProfileStyle: {
    margin: theme.spacing(0, 0, 3, 0)
  },
  accordionSummaryStyle: {
    padding: theme.spacing(2, 4.5, 2, 1.5)
  },
  accordionSummary: {
    padding: theme.spacing(3, 2, 3, 0)
  },
  accordionDetailStyle: {
    padding: theme.spacing(2, 3.5, 4.5, 2.25)
  },
  accordionSummaryExpanded: { padding: theme.spacing(3, 2, 0, 0) },
  accordionStaticContentStyle: {
    padding: theme.spacing(3, 2, 3, 0),
    backgroundColor: theme.Colors.whiteLightGrey,
    cursor: 'pointer',
    borderBottom: '0.5px solid',
    borderBottomColor: theme.Colors.greyDark
  },
  accordionStaticContentStyle1: {
    padding: theme.spacing(3, 2, 3, 0),
    backgroundColor: theme.Colors.whiteLightGrey,
    cursor: 'pointer'
  },
  accordionClassName: {
    borderBottom: '0.5px solid',
    borderBottomColor: theme.Colors.greyDark
  },
  avatarStyle: {
    height: 64,
    width: 64
  },
  textStyle: {
    fontSize: theme.MetricsSizes.small_xx + 1,
    color: theme.Colors.mediumGrey,
    fontWeight: theme.fontWeight.bold
  }
}));

type RenderIconTextProp = UHIconTextProps & {
  image?: any;
  text?: string;
  renderDetail?: () => JSX.Element;
};

const RenderIconText = (props: RenderIconTextProp) => {
  const { image, text, renderDetail, ...rest } = props;
  const classes = useStyles();

  const renderComponent = () => {
    if (renderDetail) {
      return renderDetail();
    }
    return <Typography className={classes.textStyle}>{text}</Typography>;
  };

  return (
    <UHIconTextComp icon={image} renderComponent={renderComponent} {...rest} />
  );
};

type ProfileUpdateProp = {
  language_id?: number;
  user_type_id?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  image_url?: string;
};

const Profile = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const { updateLoggedInUser, userDetails, updateUserInfo } = useUserInfo();
  const navigateTo = useNavigate();
  const [confirmModal, setConfirmModal] = useState<any>({ open: false });
  const [selectedTab, setSelectedTab] = useState<number>(
    PROFILE_TAB_VALUES.myAccount
  );

  const [openModal, setOpenModal] = useState<any>({
    open: false
  });
  const fileInputRef = useRef(null);
  const languageData = [
    { label: 'English', value: LANGUAGE_ID.english },
    { label: 'தமிழ்', value: LANGUAGE_ID.tamil },
    { label: 'Hindi', value: LANGUAGE_ID.hindi }
  ];

  const userTypeData = [
    { label: t('PROFILE.home'), value: USER_TYPE_ID.home },
    { label: t('PROFILE.commercial'), value: USER_TYPE_ID.commercial },
    { label: t('PROFILE.industry'), value: USER_TYPE_ID.industry }
  ];

  const OrdersTabItems = [
    {
      tabIcon: () =>
        selectedTab === PROFILE_TAB_VALUES.myAccount ? (
          <img src={pendingWhite} />
        ) : (
          <img src={pending} />
        ),
      label: t('PROFILE.account'),
      value: PROFILE_TAB_VALUES.myAccount
    },
    {
      tabIcon: () =>
        selectedTab === PROFILE_TAB_VALUES.changeLanguage ? (
          <img src={confirmedWhite} />
        ) : (
          <img src={confirmed} />
        ),
      label: t('PROFILE.language'),
      value: PROFILE_TAB_VALUES.changeLanguage
    },
    {
      tabIcon: () =>
        selectedTab === PROFILE_TAB_VALUES.changeUserType ? (
          <img src={completedWhite} />
        ) : (
          <img src={Completed} />
        ),
      label: t('PROFILE.userType'),
      value: PROFILE_TAB_VALUES.changeUserType
    },
    {
      tabIcon: () => <HelpOutlineOutlined />,
      label: t('PROFILE.help'),
      value: PROFILE_TAB_VALUES.help
    },
    {
      tabIcon: () => <InfoOutlined />,
      label: t('PROFILE.about'),
      value: PROFILE_TAB_VALUES.about
    },
    {
      tabIcon: () => <ExitToApp />,
      label: t('PROFILE.logout'),
      value: PROFILE_TAB_VALUES.logout
    }
  ];

  const handleUpdateProfileDetails = useCallback(
    async (updatedData: ProfileUpdateProp, sucMessage?: string) => {
      if (updatedData.language_id === 1) {
        i18n.changeLanguage('en');
        toast.success(`${t('TOAST.languageUpdatedSuccessfully')}`);
      } else if (updatedData.language_id === 2) {
        i18n.changeLanguage('ta');
        toast.success(`${t('TOAST.languageUpdatedSuccessfully')}`);
      } else if (updatedData.language_id === 3) {
        i18n.changeLanguage('hi');
        toast.success(`${t('TOAST.languageUpdatedSuccessfully')}`);
      }
      try {
        let successMessage =
          sucMessage ?? `${t('TOAST.accountDetailUpdatedSuccessfully')}`;
        const customerUpdateData = {
          ...updatedData
        };
        const response: any =
          await API_SERVICES.customerProfileService.updateCustomerProfile(
            userDetails?.id,
            { data: customerUpdateData, successMessage }
          );
        if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
          if (response?.data?.profile) {
            // window.location.reload();
            updateUserInfo(response.data.profile.customer_id);
            navigateTo('/customer-home/customer-info');
          }
        }
        return response;
      } catch (e) {
        console.log(e, '--profile update err--');
      }
    },
    []
  );

  const handleAddNewAddress = useCallback(() => {
    setOpenModal({ open: true, dialogType: DIALOG_TYPE.create });
  }, []);

  const handleVerifyOtpNumber = useCallback(
    async (data: any, successMessage: string) => {
      const response: any =
        await API_SERVICES.customerProfileService.replaceCustomer(
          userDetails?.customer_id,
          { data, successMessage }
        );
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        updateUserInfo(response?.data?.customer?.id);
      }
      return response;
    },
    []
  );

  const handleChangeFileUpload = async (event: { target: { files: any } }) => {
    try {
      const uploadedImg = event.target.files[0];
      let formData = new FormData();
      formData.append('file', uploadedImg);
      const uploadImageRes: any =
        await API_SERVICES.imageUploadService.uploadImage(formData);
      if (uploadImageRes?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (uploadImageRes?.data?.images.length) {
          let data = { image_url: uploadImageRes.data.images[0].Location };
          let successMessage = `${t('TOAST.profileImageUpdatedSuccessfully')}`;
          handleUpdateProfileDetails(data, successMessage);
        }
      }
    } catch (err) {
      toast.error(err);
    }
  };

  const handleAddressSaveButtonClick = async (
    addressData: AddressData,
    modalClose: () => void,
    dialogType: string
  ) => {
    if (dialogType === 'edit') {
      const response: any =
        await API_SERVICES.customerAddressService.replaceAddress(
          addressData?.id,
          {
            data: addressData,
            successMessage: `${t('TOAST.editAddressSuccessfully')}`,
            failureMessage: `${t('TOAST.failedtoAddNewAddress')}`
          }
        );
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (response?.data?.address) {
          let id = response?.data?.address?.customer_id;
          updateUserInfo(id);
          modalClose();
        }
      }
    } else {
      const response: any = await API_SERVICES.customerAddressService.create(
        userDetails?.customer_id,
        {
          data: addressData,
          successMessage: `${t('TOAST.newAddressAddedSuccessfully')}`,
          failureMessage: `${t('TOAST.failedtoAddNewAddress')}`
        }
      );
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (response?.data?.message) {
          let id = response?.data?.message?.customer_id;
          updateUserInfo(id);
          modalClose();
        }
      }
    }
  };

  const renderMyAccountExpandIcons = (isActiveAccordion: boolean) => {
    if (isActiveAccordion) {
      return <ExpandMore />;
    } else {
      return <ChevronRight />;
    }
  };

  const renderChangeLangUserExpandIcons = (isActiveAccordion: boolean) => {
    if (isActiveAccordion) {
      return <ExpandLess style={{ alignSelf: 'flex-start' }} />;
    } else {
      return <ExpandMore />;
    }
  };

  const onTabChange = (tabValue: any) => {
    setSelectedTab(tabValue);
  };

  const onConfirmClick = () => {
    // i18n.changeLanguage('en');
    localStorage.removeItem('customerId');
    updateLoggedInUser(INITIAL_CUSTOMER_STATE.loggedInUser);
    updateUserInfo(0);
    navigateTo('/', { replace: true });
    toast.success(`${t('TOAST.userLoggedoutSuccessfully')}`);
    setConfirmModal({ open: false });
  };

  const onCancelClick = () => {
    setConfirmModal({ open: false });
    setSelectedTab(PROFILE_TAB_VALUES.myAccount);
  };

  useLayoutEffect(() => {
    if (selectedTab === PROFILE_TAB_VALUES.logout) {
      setConfirmModal({ open: true });
    }
  }, [selectedTab]);

  const accordionContent = [
    {
      id: 1,
      accContentDetail: () => (
        <ProfileContent
          handleSaveEdits={handleUpdateProfileDetails}
          handleAddNewAddress={handleAddNewAddress}
          handleVerifyOtpNumber={handleVerifyOtpNumber}
          handleEditListItem={(item) =>
            setOpenModal({
              open: true,
              item: item,
              dialogType: DIALOG_TYPE.edit
            })
          }
        />
      ),
      renderAccordionTitle: () => (
        <>
          <ListItemCell
            isEditIcon
            onClickEditIcon={() => fileInputRef.current.click()}
            avatarImg={
              userDetails?.image_url !== ''
                ? userDetails?.image_url
                : AvatarCustomer
            }
            title={`${userDetails?.first_name} ${userDetails?.last_name}`}
            subTitle={userDetails?.email}
            avatarClassNameStyles={classes.avatarStyle}
            titleStyle={{
              color: theme.Colors.mediumGrey,
              fontWeight: theme.fontWeight.bold,
              fontSize: theme.MetricsSizes.small_xxx + 1
            }}
            subTitleStyle={{
              fontWeight: theme.fontWeight.regular,
              fontSize: theme.MetricsSizes.tiny_xxx + 1,
              color: theme.Colors.mediumBlack
            }}
          />
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            hidden
            onChange={handleChangeFileUpload}
          />
        </>
      )
    }
  ];

  const accordionContentSub = [
    {
      id: 2,
      expanded: selectedTab === PROFILE_TAB_VALUES.changeLanguage,
      accordionSummaryClassName:
        selectedTab === PROFILE_TAB_VALUES.changeLanguage
          ? classes.accordionSummaryExpanded
          : classes.accordionSummary,
      renderAccordionTitle: () =>
        selectedTab === PROFILE_TAB_VALUES.changeLanguage ? (
          <RenderIconText
            image={Translate}
            renderDetail={() => (
              <UHSelectComp
                initialValue={[userDetails?.language_id]}
                labelData={languageData}
                handleChangeItem={(selectedVal: number[]) => {
                  if (selectedVal[0] === userDetails?.language_id) {
                    return;
                  }
                  let data: ProfileUpdateProp = {
                    language_id: selectedVal[0]
                  };

                  let successMessage = '';
                  handleUpdateProfileDetails(data, successMessage);
                }}
              />
            )}
            style={{ alignItems: 'flex-start' }}
          />
        ) : (
          <RenderIconText image={Translate} text={t('PROFILE.language')} />
        )
    },
    {
      id: 3,
      accordionSummaryClassName:
        selectedTab === PROFILE_TAB_VALUES.changeUserType
          ? classes.accordionSummaryExpanded
          : classes.accordionSummary,
      expanded: selectedTab === PROFILE_TAB_VALUES.changeUserType,
      renderAccordionTitle: () =>
        selectedTab === PROFILE_TAB_VALUES.changeUserType ? (
          <RenderIconText
            image={UserSwitch}
            renderDetail={() => (
              <UHSelectComp
                initialValue={[userDetails?.user_type_id]}
                labelData={userTypeData}
                handleChangeItem={(selectedVal: number[]) => {
                  if (selectedVal[0] === userDetails?.user_type_id) {
                    return;
                  }
                  let data: ProfileUpdateProp = {
                    user_type_id: selectedVal[0]
                  };
                  let successMessage = `${t(
                    'TOAST.userTypeupdatedSuccessfully'
                  )}`;
                  handleUpdateProfileDetails(data, successMessage);
                }}
              />
            )}
            style={{ alignItems: 'flex-start' }}
          />
        ) : (
          <RenderIconText image={UserSwitch} text={t('PROFILE.userType')} />
        )
    },
    {
      id: 6,
      expanded: selectedTab === PROFILE_TAB_VALUES.about,
      accordionSummaryClassName:
        selectedTab === PROFILE_TAB_VALUES.about
          ? classes.accordionSummaryExpanded
          : classes.accordionSummary,
      renderAccordionTitle: () =>
        selectedTab === PROFILE_TAB_VALUES.about ? (
          <RenderIconText
            image={CustomerAboutInfo}
            renderDetail={() => (
              <Grid container>
                <Grid item xs={11}>
                  <Typography>{t('PROFILE.aboutas')}</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>{t('PROFILE.aboutasDetails')}</Typography>
                </Grid>
              </Grid>
            )}
          />
        ) : (
          <RenderIconText image={CustomerAboutInfo} text={t('PROFILE.about')} />
        )
    }
  ];

  const renderTabContent = () => {
    return (
      <Grid className={classes.tabContentContainer}>
        <UHAccordionComp
          config={accordionContent}
          accordionSummaryClassName={classes.accordionSummaryStyle}
          accordionOuterContainerClassName={classes.accordionProfileStyle}
          accordionDetailClassName={classes.accordionDetailStyle}
          isBorder={true}
          customActiveAccItem={[selectedTab]}
          renderExpandIcons={renderMyAccountExpandIcons}
          expanded={selectedTab === PROFILE_TAB_VALUES.myAccount}
        />
        <Grid className={classes.tabContentEachContainer}>
          <UHAccordionComp
            config={accordionContentSub}
            renderExpandIcons={renderChangeLangUserExpandIcons}
            accordionOuterContainerClassName={classes.accordionClassName}
            customActiveAccItem={[selectedTab]}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid className={classes.mainContainer}>
        <UHTabComponent
          currentTabVal={selectedTab}
          tabContent={OrdersTabItems}
          orientation={ORIENTATION.VERTICAL}
          tabClasses={{ selected: classes.selectedTabStyle }}
          tabIndicatorColor={theme.Colors.primary}
          isDivider={false}
          tabContainerClassName={classes.tabContainer}
          renderTabContent={renderTabContent}
          tabContentClassName={classes.tabContentStyle}
          onTabChange={onTabChange}
        />
      </Grid>
      {openModal.open ? (
        <UHAddressModalComp
          modalTitle={t('PROFILE.addNewAddress')}
          onClose={() => setOpenModal({ open: false })}
          handleSaveButtonClick={handleAddressSaveButtonClick}
          isCustomerModal={true}
          {...openModal}
        />
      ) : null}
      {confirmModal.open ? (
        <UHConfirmModal
          {...confirmModal}
          title={t('PROFILE.logout')}
          description={t('TOAST.areYouSurewantLogout')}
          onCancelClick={onCancelClick}
          onConfirmClick={onConfirmClick}
          color={theme.Colors.redPrimary}
          iconType={CONFIRM_MODAL.logout}
        />
      ) : null}
    </>
  );
};

export default Profile;
