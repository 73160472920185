import React from 'react';
import {
  aboutPh,
  about,
  cards,
  hisIcon1,
  hisIcon2,
  hisIcon3,
  hisIcon4,
  hisIcon5,
  line,
  satisfaction,
  statuary,
  financial,
  work,
  leftarrow,
  rating,
  quotation,
  face,
  rightarrow,
  google1,
  app1,
  iconbox1,
  iconbox2,
  iconbox3,
  iconbox4
} from 'src/Assets';
import { useNavigate } from 'react-router';
import './website.css';
import { t } from 'i18next';
function AboutUs() {
  const navigateTo = useNavigate();
  const navigationToHome = () => {
    navigateTo('/', { replace: true });
  };
  return (
    <>
      <img className="about1" src={about} alt="" />
      <section />
      <div className="about-page1">
        <div className="side-head">
          <a onClick={navigationToHome}>{t('WEBGALLERY.home')}</a> |{' '}
          <a className="current-view">{t('WEBGALLERY.AboutUs')}</a>
        </div>
        <h1 className="about-title"> {t('WEBABOUTUS.WhoWeAre')}</h1>
        <div className="about-body">
          <p>{t('WEBABOUTUS.years')}</p>
          <p>{t('WEBABOUTUS.experiance')}</p>
          <p>{t('WEBABOUTUS.quantities')}</p>
        </div>
      </div>
      <div className="about-page2">
        <h1 className="about-title1"> {t('WEBABOUTUS.OurGoal')}</h1>
        <div className="about-box">
          <p>{t('WEBABOUTUS.aboutbox')}</p>
        </div>
        <div className="about-para">
          <p>{t('WEBABOUTUS.aboutpara')}</p>
          <p>{t('WEBABOUTUS.aboutpara2')}</p>
        </div>
      </div>
      <div className="about-page3">
        <div className="row">
          <div className="about-text column-left">
            <div className="up-line"></div>
            <h1> {t('WEBABOUTUS.CustomerSatisfaction')}</h1>
            <p>{t('WEBABOUTUS.CustomerSatisfactionPara')}</p>
          </div>
          <div className="about-pics">
            <img src={satisfaction} alt="" />
          </div>
          {/* <img className="about-pics column-right" src={satisfaction} alt="" /> */}
        </div>

        <div className="row">
          {/* <div className="about-pics">
                <img src="images/statuary.png" alt="">
            </div> */}
          <img className="about-pics column-left" src={statuary} alt="" />
          <div className="about-text column-right">
            <div className="up-line"></div>
            <h1> {t('WEBABOUTUS.StatutoryRequirement')}</h1>
            <p>{t('WEBABOUTUS.StatutoryRequirementPara')}</p>
          </div>
        </div>

        <div className="row">
          <div className="about-text column-left">
            <div className="up-line"></div>
            <h1> {t('WEBABOUTUS.FinanciallySecure')}</h1>
            <p>{t('WEBABOUTUS.FinanciallySecurePara')}</p>
          </div>
           <div className="about-pics">
               <img src={financial} alt="" />
            </div> 
          {/* <img className="about-pics column-right" src={financial} alt="" /> */}
        </div>

        <div className="row">
          <img className="about-pics column-left" src={work} alt="" />
          <div className="about-text column-right">
            <div className="up-line"></div>
            <h1> {t('WEBABOUTUS.WorkForce')}</h1>
            <p>{t('WEBABOUTUS.WorkForcePara')}</p>
          </div>
        </div>
      </div>
      <div className="about-page6">
        <img className="about-ph-image" src={aboutPh} alt="" />
        <div className="about-ph-text">
          <h5> {t('WEBABOUTUS.Mobileapps')}</h5>
          <p
            style={{ fontSize: '30px', fontWeight: 700, marginBottom: '20px',gap:'2px' }}
          >
            {t('WEBHOME.bookYour')}
            <br />
            {t('WEBHOME.pickupFrom')}
            <br />
            {t('WEBHOME.ourApp')}
          </p>
          {/* <h1>Book your<br>pickup from<br>our App</h1> */}
          <p>{t('WEBABOUTUS.MobileappsPara')}</p>
          <div className="play-btn">
            <img className="play-btn-img" src={google1} alt="" />
            <img className="play-btn-img" src={app1} alt="" />
          </div>
        </div>
      </div>

      <div className="landing2">
        <div className="round">
          <img
            src={iconbox1}
            style={{ height: 60, marginTop: 5, mixBlendMode: 'darken' }}
            alt=""
          />
          <p style={{ marginTop: 6 }}> {t('WEBABOUTUS.TNPCBApproved')}</p>
        </div>

        <div className="round">
          <img src={iconbox2} alt="" />
          <p>{t('WEBABOUTUS.StaffMembers')}</p>
        </div>

        <div className="round">
          <img src={iconbox3} alt="" />
          <p>{t('WEBABOUTUS.BankSupport')}</p>
        </div>

        <div className="round">
          <img src={iconbox4} alt="" />
          <p>{t('WEBABOUTUS.AllVehicleTypes')}</p>
        </div>
      </div>
    </>
  );
}
export default AboutUs;
