import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import {
  Grid,
  Tab,
  Tabs,
  Theme,
  Typography,
  createStyles,
  makeStyles
} from '@material-ui/core';
import axios from 'axios';
import { HTTP_STATUSES } from 'src/Config/constant';
import { Config } from 'src/Config';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outerContainer: {
      background: theme.Colors.white,
      margin: theme.spacing(2, 0, 4.3, 0),
      justifyContent: 'space-between',
      width: '100%'
    },
    selectedStyle: {
      color: theme.Colors.white,
      textTransform: 'none',
      fontSize: 20,
      fontWeight: theme.fontWeight.mediumBold
    },
    textStyle: {
      color: theme.Colors.primary,
      textTransform: 'none',
      fontSize: 20,
      fontWeight: theme.fontWeight.mediumBold
    },
    root: {
      width: '100%',
      padding: '10px 30px'
    },
    selectedTab: {
      background: theme.Colors.primary
    },
    tabIndicator: {
      display: 'none'
    }
  })
);

const TermsNConditions = () => {
  const [termsContent, setTermsContent] = useState('');
  const [privacyContent, setPrivacyContent] = useState('');
  const [cancelContent, setCancelContent] = useState('');
  const [shippingContent, setShippingContent] = useState('');
  const [value, setValue] = useState(0);
  const classes = useStyles();

  let urlTerms = `${Config.BASE_URL}/termCondition`;
  let urlPrivacy = `${Config.BASE_URL}/privacyPolicy`;
  let urlCancel = `${Config.BASE_URL}/cancellationAndRefundPolicy`;
  let urlShipping = `${Config.BASE_URL}/shippingPolicy`;
  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await axios.get(urlTerms);
        const sanitizedContent = DOMPurify.sanitize(response.data);
        const privacyResponse = await axios.get(urlPrivacy);
        const sanitizedPrivacyContent = DOMPurify.sanitize(
          privacyResponse.data
        );
        const cancelResponse = await axios.get(urlCancel);
        const sanitizedCancelContent = DOMPurify.sanitize(cancelResponse.data);
        const shippingResponse = await axios.get(urlShipping);
        const sanitizedShippingContent = DOMPurify.sanitize(
          shippingResponse.data
        );
        console.log('sanitizedCancelContent', sanitizedCancelContent);
        console.log('sanitizedShippingContent', sanitizedShippingContent);

        if (response.status < HTTP_STATUSES.BAD_REQUEST) {
          setTermsContent(sanitizedContent);
        }
        if (privacyResponse.status < HTTP_STATUSES.BAD_REQUEST) {
          setPrivacyContent(sanitizedPrivacyContent);
        }
        if (cancelResponse.status < HTTP_STATUSES.BAD_REQUEST) {
          setCancelContent(sanitizedCancelContent);
        }
        if (shippingResponse.status < HTTP_STATUSES.BAD_REQUEST) {
          setShippingContent(sanitizedShippingContent);
        }
      } catch (error) {
        console.error('Error fetching content:', error);
      }
    };

    fetchContent();
  }, [value]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item xs={12}>
        <Tabs
          value={value}
          onChange={handleTabChange}
          variant="fullWidth"
          textColor="primary"
          className={classes.outerContainer}
          classes={{
            indicator: classes.tabIndicator
          }}
        >
          <Tab
            label={
              <Typography
                className={
                  value === 0 ? classes.selectedStyle : classes.textStyle
                }
              >
                Terms And Conditions
              </Typography>
            }
            classes={{
              selected: classes.selectedTab
            }}
          />
          <Tab
            label={
              <Typography
                className={
                  value === 1 ? classes.selectedStyle : classes.textStyle
                }
              >
                Privacy Policy
              </Typography>
            }
            classes={{
              selected: classes.selectedTab
            }}
          />
          <Tab
            label={
              <Typography
                className={
                  value === 2 ? classes.selectedStyle : classes.textStyle
                }
              >
                Shipping Policy
              </Typography>
            }
            classes={{
              selected: classes.selectedTab
            }}
          />
          <Tab
            label={
              <Typography
                className={
                  value === 3 ? classes.selectedStyle : classes.textStyle
                }
              >
                Cancelation and Refund Policy
              </Typography>
            }
            classes={{
              selected: classes.selectedTab
            }}
          />
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        <div
          dangerouslySetInnerHTML={{
            __html:
              value === 0
                ? termsContent
                : value === 1
                ? privacyContent
                : value === 2
                ? shippingContent
                : cancelContent
          }}
        ></div>
      </Grid>
    </Grid>
  );
};

export default TermsNConditions;
